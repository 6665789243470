import { FC, useMemo } from 'react';

import {
    FilterTypeEnum,
    OperatorProfilePhrasesEnum,
    OperatorTargetingPhrasesEnum,
    TableColumnsEnum,
} from '@enums/localeEnum';
import { useLocale } from '@core/utils/locale';
import { useSearchInput } from '@core/hooks/useInput';
import { IconFilterRestaurants } from '@assets/icons';
import { RangesTypesEnum } from '@core/api/useRanges';
import { CuisinesResponse } from '@core/api/useCuisines';
import { GradientColorsEnum } from '@enums/gradientEnum';
import BadgeIcon from '@components/utility/BadgeIcon/BadgeIcon';
import { formatTestIds, generateAnchorId } from '@core/utils/string';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import {
    filterAndParseSegmentsChannels,
    mapChannelListToSelectOptions,
} from '@pages/OperatorTargetingCriteria/helpers/Classification.helpers';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import Button, { ButtonTypeEnum } from '@components/utility/Button/Button';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { CriterionEnum, CriterionParamFilter } from '@core/models/Criterion';
import SearchInput from '@components/utility/Inputs/SearchInput/SearchInput';
import { FILTER_ID_PREFIX } from '@pages/OperatorTargetingCriteria/hooks/useSearchNavigation';

import CuisinesChannel from './CuisinesChannel';
import OnTheMenuCriteria from './OnTheMenuCriteria';
import CuisinesSwitchList from './CuisinesSwitchList';
import { RangesData } from '../GeneralCriteria/General.helpers';
import OnTheMenuIncludesCheckbox from './OnTheMenuIncludesCheckbox';

const RestaurantsAndBarsCriteria: FC<{
    ranges: RangesData | null;
    cuisines: CuisinesResponse | null;
    hasMenuData?: boolean;
}> = ({
    ranges,
    cuisines,
    hasMenuData
}) => {
    const { t } = useLocale();
    const { onResetCriterionParamsByKey } = useAdvancedSearch();
    const isAverageCheckEnabled = useFeatureFlag(FeatureFlags.AverageCheck);
    const isEstMealsPerDayEnabled = useFeatureFlag(FeatureFlags.EstMealsPerDay);
    const isMenuSearchEnabled = useFeatureFlag(FeatureFlags.MenuSearch);

    const {
        value: searchSelectedValue,
        onChange
    } = useSearchInput();

    const parsedCuisines = useMemo(
        () => filterAndParseSegmentsChannels(cuisines?.children) ?? [],
        [cuisines?.children],
    );

    const searchInputOptions = useMemo(() => mapChannelListToSelectOptions(parsedCuisines), [parsedCuisines]);

    return (
        <div className="CriteriaContent with-gap" id={generateAnchorId(t(FilterTypeEnum.RestaurantsAndBars))}>
            <div className="CriteriaContent__header">
                <BadgeIcon icon={<IconFilterRestaurants />} baseColor={GradientColorsEnum.PURPLE} />
                <Heading>{t(FilterTypeEnum.RestaurantsAndBars)}</Heading>
                <SearchInput
                    options={searchInputOptions}
                    highlightResults
                    onChangeCallback={onChange}
                    value={searchSelectedValue}
                    testId="CuisineTypesSearch"
                />

                <Button
                    modifiers={['naked', 'auto']}
                    type={ButtonTypeEnum.Link}
                    onClick={() => onResetCriterionParamsByKey(CriterionEnum.CuisineTypes)}
                    style={{ marginLeft: 'auto' }}
                    testId="ButtonCuisineTypesResetAll"
                >
                    {t(OperatorTargetingPhrasesEnum.ResetAll)}
                </Button>
            </div>

            {parsedCuisines?.length > 0 && (
                <Widget
                    type={WidgetType.Section}
                    className="criteria"
                    id={generateAnchorId(t(TableColumnsEnum.Cuisine), FILTER_ID_PREFIX)}
                    dataObservable
                >
                    <Heading variant={HeadingVariant.H5} testId={FilterTypeEnum.CuisineTypes}>
                        {t(FilterTypeEnum.CuisineTypes)}
                    </Heading>
                    {parsedCuisines?.map((channel) => {
                        return (
                            <CuisinesChannel
                                searchSelectedValue={searchSelectedValue}
                                key={channel?.description}
                                channel={channel}
                                onClearSearch={() => {
                                    onChange(null);
                                }}
                            />
                        );
                    })}
                </Widget>
            )}

            {isMenuSearchEnabled && hasMenuData && (
                <Widget
                    type={WidgetType.Section}
                    className="criteria GeneralCriteria__section"
                    id={generateAnchorId(t(OperatorProfilePhrasesEnum.Menu), FILTER_ID_PREFIX)}
                    dataObservable
                >
                    <div className="CriteriaContent__columns no-mt col-2">
                        <div className="CriteriaContent__column col-1">
                            <Heading
                                className="GeneralCriteria__range-title"
                                variant={HeadingVariant.H5}
                                testId={formatTestIds(OperatorProfilePhrasesEnum.Menu)}
                            >
                                {t(OperatorProfilePhrasesEnum.Menu)}
                            </Heading>
                        </div>
                        <div className="GeneralCriteria__input-container">
                            <OnTheMenuCriteria
                                criterionFilterKey={CriterionParamFilter.MenuIncludes}
                                title={FilterTypeEnum.MenuIncludes}
                            />
                        </div>
                        <div className="GeneralCriteria__input-container">
                            <OnTheMenuCriteria
                                criterionFilterKey={CriterionParamFilter.MenuExcludes}
                                title={FilterTypeEnum.MenuExcludes}
                            />
                        </div>
                        <OnTheMenuIncludesCheckbox />
                    </div>
                </Widget>
            )}

            {!!ranges?.[RangesTypesEnum.EstMealsPerDayRangeType] && isEstMealsPerDayEnabled && (
                <Widget
                    type={WidgetType.Section}
                    className="criteria GeneralCriteria__section"
                    id={generateAnchorId(t(OperatorProfilePhrasesEnum.EstMealsPerDay), FILTER_ID_PREFIX)}
                    dataObservable
                >
                    <CuisinesSwitchList
                        range={ranges[RangesTypesEnum.EstMealsPerDayRangeType]}
                        testId={OperatorProfilePhrasesEnum.EstMealsPerDay}
                        title={`${t(OperatorProfilePhrasesEnum.EstMealsPerDay)}`}
                        subtitle={` (${t(OperatorProfilePhrasesEnum.AverageDailyMeals).toLocaleLowerCase()})`}
                        criterionFilterKey={CriterionParamFilter.EstMealsPerDayRange}
                    />
                </Widget>
            )}

            {!!ranges?.[RangesTypesEnum.AverageCheckRangeType] && isAverageCheckEnabled && (
                <Widget
                    type={WidgetType.Section}
                    className="criteria GeneralCriteria__section"
                    id={generateAnchorId(t(OperatorTargetingPhrasesEnum.AverageCheck), FILTER_ID_PREFIX)}
                    dataObservable
                >
                    <CuisinesSwitchList
                        range={ranges[RangesTypesEnum.AverageCheckRangeType]}
                        testId={OperatorTargetingPhrasesEnum.AverageCheck}
                        title={`${t(OperatorTargetingPhrasesEnum.AverageCheck)}`}
                        criterionFilterKey={CriterionParamFilter.AverageCheckRange}
                    />
                </Widget>
            )}
        </div>
    );
};

export default RestaurantsAndBarsCriteria;
