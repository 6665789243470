import { FC, useEffect, useMemo } from 'react';

import { useLocale } from '@core/utils/locale';
import { FilterTypeEnum } from '@enums/localeEnum';
import { IconFilterLocation } from '@assets/icons';
import Heading from '@components/ui/Typography/Heading';
import { GradientColorsEnum } from '@enums/gradientEnum';
import BadgeIcon from '@components/utility/BadgeIcon/BadgeIcon';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import useListenToActiveMenuItem from '@pages/OperatorTargetingCriteria/hooks/useListenToActiveMenuItem';

import './LocationContent.styles.scss';
import { useAppContext } from '../../../../App.context';
import RadiusCriteria from './RadiusCriteria/RadiusCriteria';
import RegionalCriteria from './RegionalCriteria/RegionalCriteria';
import TerritoryFavoritesCriteria from './TerritoryFavoritesCriteria/TerritoryFavoritesCriteria';
import { getSafeBottomMargin } from '@pages/OperatorTargetingCriteria/helpers/Criteria.helpers';

const LocationContent: FC<{
    onUpdateActiveNavigationSubItem: (id: string) => void;
}> = ({ onUpdateActiveNavigationSubItem }) => {
    const { t } = useLocale();
    const { hasTerritoryAccess } = useAppContext();
    const isRadiusSectionEnabled = useFeatureFlag(FeatureFlags.RadiusSection);
    const isLocationTerritoriesFavoritesEnabled = useFeatureFlag(FeatureFlags.LocationTerritoriesFavorites);

    const { observe } = useListenToActiveMenuItem(
        onUpdateActiveNavigationSubItem,
        '[data-observable]',
        '#observer-root',
    );

    useEffect(() => {
        setTimeout(() => {
            observe();
        }, 1500);
    }, [observe]);

    const safeBottomMargin = useMemo(() => getSafeBottomMargin(), []);

    return (
        <div className="CriteriaContent LocationContent" style={{ marginBottom: safeBottomMargin }}>
            <div className="CriteriaContent__header">
                <BadgeIcon icon={<IconFilterLocation />} baseColor={GradientColorsEnum.GREEN} />
                <Heading testId="LocationSection">{t(FilterTypeEnum.Location)}</Heading>
            </div>
            {isLocationTerritoriesFavoritesEnabled && hasTerritoryAccess && <TerritoryFavoritesCriteria />}
            <RegionalCriteria />

            {isRadiusSectionEnabled && <RadiusCriteria />}
        </div>
    );
};

export default LocationContent;
