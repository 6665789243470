import { FC, useCallback, useMemo } from 'react';

import { useLocale } from '@core/utils/locale';
import { CriterionEnum, CriterionParamFilter } from '@core/models/Criterion';
import { OperatorTargetingPhrasesEnum } from '@core/enums/localeEnum';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import Button, { ButtonTypeEnum } from '@components/utility/Button/Button';
import { TerritoryDTO } from '@core/api/useTerritories';

const TerritoryActionButton: FC<{
    territories: TerritoryDTO[];
    onClearAllTerritorySegments: () => void;
    onSelectAllTerritorySegments: () => void;
}> = ({
    territories,
    onClearAllTerritorySegments,
    onSelectAllTerritorySegments
}) => {
    const { getCriterionByFilterKey } = useAdvancedSearch();
    const { t } = useLocale();

    const areAllTerritoriesSelected = useMemo(() => {
        const territoryParams = getCriterionByFilterKey(CriterionEnum.Location, CriterionParamFilter.Territories);
        return (
            territories.every((territory) => territoryParams?.some((param) => param.value === territory.name)) ?? false
        );
    }, [territories, getCriterionByFilterKey]);

    const handleClearAll = useCallback(() => {
        onClearAllTerritorySegments();
    }, [onClearAllTerritorySegments]);

    const handleSelectAll = useCallback(() => {
        onSelectAllTerritorySegments();
    }, [onSelectAllTerritorySegments]);

    const handleButtonClick = useCallback(() => {
        if (areAllTerritoriesSelected) {
            handleClearAll();
        } else {
            handleSelectAll();
        }
    }, [areAllTerritoriesSelected, handleClearAll, handleSelectAll]);

    const areAllTerritoriesDisabled = useMemo(() => {
        return territories.length === 0;
    }, [territories]);

    return (
        <Button
            modifiers={['naked', 'auto']}
            type={ButtonTypeEnum.Link}
            onClick={handleButtonClick}
            isDisabled={areAllTerritoriesDisabled}
            testId={areAllTerritoriesSelected ? 'ButtonTerritoriesClearAll' : 'ButtonTerritoriesSelectAll'}
        >
            {areAllTerritoriesSelected
                ? t(OperatorTargetingPhrasesEnum.ClearAll)
                : t(OperatorTargetingPhrasesEnum.SelectAll)}
        </Button>
    );
};

export default TerritoryActionButton;
