import { useCallback, useState } from 'react';
import cn from 'classnames';

import { FCX, ID, iOption } from '@core/models';
import { IconTableFilter } from '@assets/icons';
import Popover from '@components/utility/Popover/Popover';
import { DEFAULT_POPOVER_POSITION } from '@components/utility/Inputs/Inputs.helpers';

import { onFilterColumn } from '../Table.helpers';
import TableFilterDropdown from './TableFilterDropdown';

const TableFilter: FCX<{
    field: string;
    onFilter: onFilterColumn;
    options: iOption[];
    selectedOptions: ID[];
}> = ({
    field,
    options,
    onFilter,
    selectedOptions
}) => {
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const onFilterCallback = useCallback((opts: ID[]) => onFilter(field, opts), [field, onFilter]);

    const onClose = useCallback((e: React.MouseEvent) => {
        e?.stopPropagation();
        setIsFilterOpen(false);
    }, []);

    return (
        <div
            className={cn('Table__filter', { 'Table__filter--active': isFilterOpen || selectedOptions.length > 0 })}
            onClick={(e) => {
                if (e.target === e.currentTarget || e.currentTarget.contains(e.target as Node)) {
                    e.stopPropagation();
                    setAnchorEl(e.currentTarget);
                    setIsFilterOpen(true);
                }
            }}
        >
            <IconTableFilter className={cn('Table__filter-icon', { 'Table__filter--active': isFilterOpen })} />
            <Popover {...DEFAULT_POPOVER_POSITION} open={isFilterOpen} onClose={onClose} anchorEl={anchorEl}>
                <TableFilterDropdown
                    options={options}
                    selectedOptions={selectedOptions}
                    onFilterCallback={onFilterCallback}
                    onClose={onClose}
                />
            </Popover>
        </div>
    );
};

export default TableFilter;
