import { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ID, iOption } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { Country } from '@core/enums/flagsEnum';
import { useAdvancedSearch } from '@core/contexts';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { toggleInArray } from '@core/helpers/helpers';
import { containsSubstring } from '@core/utils/string';
import { CRITERIA } from '@core/hooks/useAdvancedSearchCriteria';
import { useSelectCountryModal } from '@components/features/SelectCountryModal';
import { Dictionary, LocalesEnum, TerritoriesPhrasesEnum } from '@core/enums/localeEnum';
import { CriteriaData, CriterionEnum, CriterionParam, CriterionParamFilter } from '@core/models/Criterion';
import useTerritories, { ParsedTerritoryDTO, TerritoriesTierEnum, TerritoryDTO } from '@core/api/useTerritories';
import { onFilterColumn } from '@components/utility/Table/Table.helpers';

export type TerritorySelection = {
    toggleById: (id: ID) => void;
    isSelected: (id: ID) => boolean;
    isAllSelected: boolean;
    toggleAll: () => void;
    selectedTerritoryIds: ID[];
};

export type TerritoriesColumnFilter = {
    onFilterColumns: onFilterColumn;
    groupsList: iOption[];
    selectedGroups: ID[];
};

type ColumnFilter = {
    field: string;
    values: ID[];
};

type UseTerritoriesPageReturn = {
    territoriesList: ParsedTerritoryDTO[];
    isTerritoriesLoading: boolean;
    territorySelection: TerritorySelection;
    countryModal: ReturnType<typeof useSelectCountryModal>;
    targetTerritoriesAndNavigate: (route: RoutesToNavigate) => void;
    search: {
        territoriesSearch: string;
        setTerritoriesSearch: (search: string) => void;
    };
    drawer: {
        activeTerritoryDrawer: ParsedTerritoryDTO | null;
        isDrawerOpen: boolean;
        onOpenTerritoryDrawer: (dto: ParsedTerritoryDTO) => void;
        onCloseTerritoryDrawer: () => void;
    };
    filter: TerritoriesColumnFilter;
};

type RoutesToNavigate = RoutesEnum.OperatorTargetingCriteria | RoutesEnum.OperatorTargetingResults;

function useTerritoriesPage(): UseTerritoriesPageReturn {
    const [territoriesSearch, setTerritoriesSearch] = useState<string>('');
    const [selectedTerritoryIds, setSelectedTerritoryIds] = useState<ID[]>([]);
    const [columnFilters, setColumnFilters] = useState<ColumnFilter | null>(null);
    const [activeTerritoryDrawer, setActiveTerritoryDrawer] = useState<ParsedTerritoryDTO | null>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

    const { locale } = useLocale();
    const navigate = useNavigate();
    const countryModal = useSelectCountryModal();

    const {
        activeCountry,
        onMergeCriteria,
        onAddCriterionParam,
        getCriterionByFilterKey,
        onResetCriteriaByFilterKey
    } =
        useAdvancedSearch();

    const {
        data,
        loading: isTerritoriesLoading,
        doFetch
    } = useTerritories();

    const territoriesList = useMemo(() => {
        const parsedTerritories = parseTerritoriesData(data?.territories ?? []);
        const filteredByNameOrGroup = parsedTerritories.filter(
            (territory) =>
                containsSubstring(territory.name, territoriesSearch, true) ||
                containsSubstring(territory?.group ?? '', territoriesSearch, true),
        );

        if (!columnFilters) return filteredByNameOrGroup;

        return filteredByNameOrGroup.filter((territory) => {
            const territoryValue = territory?.[columnFilters.field as keyof ParsedTerritoryDTO] as string | undefined;

            const includeEmpty = columnFilters.values.includes('');

            if (!territoryValue) {
                return includeEmpty;
            }

            return columnFilters.values.includes(territoryValue);
        });
    }, [columnFilters, data?.territories, territoriesSearch]);

    const groupsList: iOption[] = useMemo(() => {
        const parsedTerritories = parseTerritoriesData(data?.territories ?? []);

        return (
            parsedTerritories
                .flatMap((territory) => {
                    // Although the it's for the group column, on the current iteration we will show the subgroup
                    return territory?.subgroup ? [{ id: territory.subgroup, title: territory.subgroup }] : [];
                })
                .filter((group, index, self) => self.findIndex((g) => g.id === group.id) === index) ?? []
        );
    }, [data?.territories]);

    const territoriesCriteria = useMemo(
        () => getCriterionByFilterKey(CriterionEnum.Location, CriterionParamFilter.Territories) ?? [],
        [getCriterionByFilterKey],
    );

    useEffect(() => {
        if (activeCountry?.value) {
            countryModal.setSelectedCountry({ name: activeCountry.value, code: activeCountry.value } as Country);
            doFetch({ params: { country: activeCountry.value as string } });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doFetch, activeCountry?.value]);

    useEffect(() => {
        const territoryCriteriaIds =
            territoriesCriteria
                ?.filter((trCriterion) => territoriesList.some((tr) => tr.name === trCriterion.value))
                .map((trCriterion) => trCriterion.value) || [];

        setSelectedTerritoryIds(territoryCriteriaIds);
    }, [territoriesCriteria, territoriesList]);

    const onAddTerritoriesToCriteria = useCallback(
        (trList: ParsedTerritoryDTO[]) => {
            const territoryParams = mapTerritoriesToCriterionParam(trList, locale);
            territoryParams?.forEach((territoryParam) => {
                onAddCriterionParam(CriterionEnum.Location, territoryParam);
            });
        },
        [locale, onAddCriterionParam],
    );

    const onToggleTerritory = useCallback(
        (trId: ID) => {
            const newSelectedTerritories = toggleInArray(selectedTerritoryIds, trId);
            const activeTerritoryCriteria = territoriesList.filter((tr) => newSelectedTerritories.includes(tr.name));

            setSelectedTerritoryIds(newSelectedTerritories);
            onResetCriteriaByFilterKey(CriterionEnum.Location, CriterionParamFilter.Territories);
            onAddTerritoriesToCriteria(activeTerritoryCriteria);
        },
        [onAddTerritoriesToCriteria, onResetCriteriaByFilterKey, selectedTerritoryIds, territoriesList],
    );

    const onToggleAllTerritories = useCallback(() => {
        if (selectedTerritoryIds.length === territoriesList.length) {
            onResetCriteriaByFilterKey(CriterionEnum.Location, CriterionParamFilter.Territories);
            return;
        }

        setSelectedTerritoryIds(territoriesList.map(({ name }) => name));
        onResetCriteriaByFilterKey(CriterionEnum.Location, CriterionParamFilter.Territories);
        onAddTerritoriesToCriteria(territoriesList);
    }, [onAddTerritoriesToCriteria, onResetCriteriaByFilterKey, selectedTerritoryIds.length, territoriesList]);

    const targetTerritoriesAndNavigate = useCallback(
        (route: RoutesToNavigate) => {
            if (!activeCountry) return;

            const territoryCriteria = getTerritoryCriteria([activeCountry, ...territoriesCriteria]);

            onMergeCriteria(territoryCriteria);
            navigate(route, { state: { from: RoutesEnum.Territories } });
        },
        [activeCountry, navigate, onMergeCriteria, territoriesCriteria],
    );

    const handleCountryChange = useCallback(
        (country: Country) => {
            const newCountryCriterion: CriterionParam = {
                value: country.code,
                name: country.name,
                filterKey: CriterionParamFilter.Country,
            };

            onAddCriterionParam(CriterionEnum.Location, newCountryCriterion);
            countryModal.setIsModalOpen(false);
        },
        [onAddCriterionParam, countryModal],
    );

    const onFilterColumns: onFilterColumn = useCallback((field, values) => {
        if (!values.length) {
            return setColumnFilters(null);
        }

        setColumnFilters({ field, values });
    }, []);

    return {
        territoriesList,
        isTerritoriesLoading,
        targetTerritoriesAndNavigate,
        territorySelection: {
            toggleById: onToggleTerritory,
            toggleAll: onToggleAllTerritories,
            isSelected: (id: ID) => selectedTerritoryIds.includes(id),
            isAllSelected: selectedTerritoryIds.length === territoriesList.length,
            selectedTerritoryIds,
        },
        countryModal: {
            ...countryModal,
            handleCountryChange,
        },
        drawer: {
            activeTerritoryDrawer,
            isDrawerOpen,
            onOpenTerritoryDrawer: (dto: ParsedTerritoryDTO) => {
                setActiveTerritoryDrawer(dto);
                setIsDrawerOpen(true);
            },
            onCloseTerritoryDrawer: () => {
                setIsDrawerOpen(false);
                setActiveTerritoryDrawer(null);
            },
        },
        search: {
            territoriesSearch,
            setTerritoriesSearch,
        },
        filter: {
            onFilterColumns,
            groupsList,
            selectedGroups: columnFilters?.values ?? [],
        },
    };
}

export function parseTerritoriesData(territories: TerritoryDTO[]): ParsedTerritoryDTO[] {
    const parseGroup = (group: TerritoryDTO): ParsedTerritoryDTO[] => {
        return group?.children?.flatMap((subgroup) => parseSubgroup(subgroup, group.name)) ?? [];
    };

    const parseSubgroup = (subgroup: TerritoryDTO, group = ''): ParsedTerritoryDTO[] => {
        return (
            subgroup?.children?.map((territory) => ({
                ...territory,
                subgroup: subgroup.name,
                ...(group && { group }),
            })) ?? []
        );
    };

    const parseTerritory = (dto: TerritoryDTO): ParsedTerritoryDTO[] => {
        if (dto.tier === TerritoriesTierEnum.GROUP) {
            return parseGroup(dto);
        }

        if (dto.tier === TerritoriesTierEnum.SUBGROUP) {
            return parseSubgroup(dto);
        }

        return [{ ...dto }];
    };

    return territories?.flatMap(parseTerritory) ?? [];
}

export function mapTerritoriesToCriterionParam(dtos: ParsedTerritoryDTO[], locale: LocalesEnum): CriterionParam[] {
    const phraseKey = dtos.length < 3 ? TerritoriesPhrasesEnum.Territory : TerritoriesPhrasesEnum.Territories;

    return dtos.map((dto) => ({
        value: dto.name,
        name: `${Dictionary[phraseKey][locale]}: ${dto?.name}`,
        filterKey: CriterionParamFilter.Territories,
        additionalData: { details: dto?.details, condensedLabel: dto?.name },
    }));
}

export function getTerritoryCriteria(criterionParams: CriterionParam[]): Partial<CriteriaData> {
    return {
        [CriterionEnum.Location]: {
            ...CRITERIA[CriterionEnum.Location],
            CriterionParams: criterionParams,
        },
    };
}

export function getTerritoryHierarchy(dto: ParsedTerritoryDTO): string {
    return [dto?.group, dto?.subgroup].filter(Boolean).join(' / ');
}

export default useTerritoriesPage;
