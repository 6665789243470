import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from '@mui/material';

import { FCX, ID } from '@core/models';
import { IconPlusSign } from '@assets/icons';
import { useLocale } from '@core/utils/locale';
import { formatTestIds } from '@core/utils/string';
import { ParsedTerritoryDTO, TerritoryDTO } from '@core/api/useTerritories';
import { GradientColorsEnum } from '@enums/gradientEnum';
import TerritoryDrawer from '@components/features/TerritoryDrawer';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { CriterionEnum, CriterionParamFilter } from '@core/models/Criterion';
import SwitchList, { SwitchListOption } from '@components/utility/SwitchList/SwitchList';
import {
    getTerritoryCriteria,
    getTerritoryHierarchy,
    mapTerritoriesToCriterionParam,
    parseTerritoriesData,
} from '@pages/Territories/hooks/useTerritoriesPage';
import Button, { ButtonTypeEnum } from '@components/utility/Button/Button';
import './TerritoryFavoritesCriteria.styles.scss';
import TerritoryActionButton from './TerritoryActionButton';
import { TerritoriesPhrasesEnum } from '@core/enums/localeEnum';
import Collapse from '@components/utility/Collapse/Collapse';
import { WidgetType } from '@components/utility/Widget/Widget';

const ADD_TERRITORY_ID = 'add-territory';

const TerritorySwitchList: FCX<{
    territories?: TerritoryDTO[];
    criterionFilterKey: CriterionParamFilter;
    handleAddTerritoryClick: () => void;
}> = ({
    territories,
    criterionFilterKey,
    testId,
    handleAddTerritoryClick
}) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [selectedTerritories, setSelectedTerritories] = useState<ID[]>([]);
    const [activeTerritoryDrawer, setActiveTerritoryDrawer] = useState<ParsedTerritoryDTO | null>(null);

    const {
        t,
        locale
    } = useLocale();
    const isSmallScreen = useMediaQuery('(max-width:1200px)');
    const {
        getCriterionByFilterKey,
        onMergeCriteria,
        onAddCriterionParam,
        onResetCriteriaByFilterKey,
        activeCountry
    } =
        useAdvancedSearch();

    const favoriteTerritories = useMemo(
        () => parseTerritoriesData(territories ?? []).filter((territory) => territory.favorite),
        [territories],
    );

    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleCollapseChange = (collapsed: boolean) => {
        setIsCollapsed(!collapsed);
    };

    const parsedTerritoryOptions: SwitchListOption[] = useMemo(
        () => [
            ...favoriteTerritories.map((territory) => ({
                id: territory.name,
                title: territory.name,
                subtitle: getTerritoryHierarchy(territory),
                customContent: (
                    <Button
                        type={ButtonTypeEnum.Link}
                        modifiers={['naked', 'auto']}
                        testId="ButtonPreviewTerritory"
                        className="TerritorySwitchList__preview-button"
                        onClick={() => {
                            setIsDrawerOpen(true);
                            setActiveTerritoryDrawer(territory);
                        }}
                    >
                        {t(TerritoriesPhrasesEnum.Preview)}
                    </Button>
                ),
            })),
            {
                id: ADD_TERRITORY_ID,
                title: t(TerritoriesPhrasesEnum.AddTerritory),
                subtitle: '',
                icon: <IconPlusSign />,
                className: 'SwitchList__add-territory',
            },
        ],
        [favoriteTerritories, t],
    );

    const onAddTerritoriesToCriteria = useCallback(
        (trList: TerritoryDTO[]) => {
            const territoryParams = mapTerritoriesToCriterionParam(trList, locale);
            territoryParams?.forEach((territoryParam) => {
                onAddCriterionParam(CriterionEnum.Location, territoryParam);
            });
        },
        [locale, onAddCriterionParam],
    );

    const onUpdateSelectedTerritory = useCallback(
        (dto: TerritoryDTO) => {
            if (!activeCountry) return;

            const territoryCriteria = getTerritoryCriteria([
                activeCountry,
                ...mapTerritoriesToCriterionParam([dto], locale),
            ]);
            const activeTerritoryCriteria = favoriteTerritories.filter((tr) => selectedTerritories.includes(tr.name));
            onMergeCriteria(territoryCriteria);
            onAddTerritoriesToCriteria(activeTerritoryCriteria);
        },
        [activeCountry, favoriteTerritories, locale, onAddTerritoriesToCriteria, onMergeCriteria, selectedTerritories],
    );

    useEffect(() => {
        const territoryParams = getCriterionByFilterKey(CriterionEnum.Location, criterionFilterKey);

        const newSelectedIds =
            territoryParams
                ?.flatMap((territory) => parsedTerritoryOptions.filter((opt) => territory.value === opt.id))
                .map((opt) => opt.id) ?? [];

        setSelectedTerritories((prevSelectedIds) => {
            const newSelectedSet = new Set(newSelectedIds);
            return prevSelectedIds
                .filter((id) => newSelectedSet.has(id))
                .concat(newSelectedIds.filter((id) => !prevSelectedIds.includes(id)));
        });
    }, [criterionFilterKey, getCriterionByFilterKey, parsedTerritoryOptions]);

    const onUpdateSelectedItems = useCallback(
        (values: ID[]) => {
            const symmetricDiff = selectedTerritories
                .filter((x) => !values.includes(x))
                .concat(values.filter((x) => !selectedTerritories.includes(x)))[0];

            const optionToToggle = parsedTerritoryOptions.find((territory) => territory.id === symmetricDiff);

            if (!optionToToggle) {
                return;
            }

            if (optionToToggle.id === ADD_TERRITORY_ID) {
                handleAddTerritoryClick();
                return;
            }

            const selectedTerritory = favoriteTerritories.find((tr) => tr.name === optionToToggle.id);
            if (selectedTerritory) {
                onUpdateSelectedTerritory(selectedTerritory);
            }
        },
        [
            favoriteTerritories,
            handleAddTerritoryClick,
            onUpdateSelectedTerritory,
            parsedTerritoryOptions,
            selectedTerritories,
        ],
    );

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.stopPropagation();
        }
    };

    const onClearAllTerritorySegments = useCallback(() => {
        onResetCriteriaByFilterKey(CriterionEnum.Location, CriterionParamFilter.Territories);
        setSelectedTerritories([]);
    }, [onResetCriteriaByFilterKey]);

    const onSelectAllTerritorySegments = useCallback(() => {
        setSelectedTerritories(favoriteTerritories.map(({ name }) => name));
        onResetCriteriaByFilterKey(CriterionEnum.Location, CriterionParamFilter.Territories);
        onAddTerritoriesToCriteria(favoriteTerritories);
    }, [favoriteTerritories, onAddTerritoriesToCriteria, onResetCriteriaByFilterKey]);

    return (
        <Collapse
            title={
                <div className="TerritorySwitchList__header-container">
                    <Heading
                        className="TerritorySwitchList__title"
                        variant={HeadingVariant.H4}
                        testId={formatTestIds(testId)}
                    >
                        {t(TerritoriesPhrasesEnum.TerritoryFavorites)}
                    </Heading>
                    <div
                        className="TerritorySwitchList__header"
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={handleKeyDown}
                    >
                        {isCollapsed && favoriteTerritories.length > 0 && (
                            <TerritoryActionButton
                                territories={favoriteTerritories}
                                onClearAllTerritorySegments={onClearAllTerritorySegments}
                                onSelectAllTerritorySegments={onSelectAllTerritorySegments}
                            />
                        )}
                    </div>
                </div>
            }
            startCollapsed={false}
            widgetProps={{
                type: WidgetType.Section,
                className: 'section-small',
            }}
            onCollapseChange={handleCollapseChange}
        >
            <div className="TerritorySwitchList">
                <SwitchList
                    options={parsedTerritoryOptions}
                    value={selectedTerritories}
                    onChange={onUpdateSelectedItems}
                    color={GradientColorsEnum.GREEN}
                    isSmall={isSmallScreen}
                    columns={3}
                    mobileColumns={1}
                    className="TerritorySwitchList__custom"
                />
                <TerritoryDrawer
                    isOpen={isDrawerOpen}
                    handleClose={() => {
                        setIsDrawerOpen(false);
                        setActiveTerritoryDrawer(null);
                    }}
                    territory={activeTerritoryDrawer}
                />
            </div>
        </Collapse>
    );
};

export default TerritorySwitchList;
