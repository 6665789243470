import { FC, useCallback, useState } from 'react';

import { useLocale } from '@core/utils/locale';
import { IconDoubleArrow } from '@assets/icons';
import { MenuResponse } from '@core/api/useGlobalMenu';
import Button from '@components/utility/Button/Button';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import Tabs, { TabsVariant } from '@components/utility/Tabs/Tabs';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';

import './Menu.styles.scss';
import MenuSection from './MenuSection';
import useGetMenuTabs from './useGetMenuTabs';

const MENU_ANCHOR_ID = 'menu-container-anchor';

const Menu: FC<{
    menuData: MenuResponse;
    countryCode: CountryCodesEnum;
}> = ({
    menuData,
    countryCode
}) => {
    const [triggerActiveTab, setTriggerActiveTab] = useState<boolean>(true);

    const { t } = useLocale();

    const {
        tabsContent,
        shouldRenderTabs,
        emptyHeaderItems
    } = useGetMenuTabs(menuData, countryCode);

    const scrollToMenuTop = useCallback(() => {
        const menuContainer = document.getElementById(MENU_ANCHOR_ID);
        if (menuContainer) {
            setTriggerActiveTab((prev) => !prev);
            menuContainer.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    return (
        <>
            <Widget
                type={WidgetType.Section}
                className="MenuTabs"
                id={MENU_ANCHOR_ID}
                style={{ scrollMarginTop: '80px' }}
            >
                {shouldRenderTabs ? (
                    tabsContent.length > 0 && (
                        <Tabs
                            tabsContent={tabsContent}
                            triggerResetActiveTab={triggerActiveTab}
                            variant={TabsVariant.HorizontalScroll}
                            hasSharedContent={true}
                        />
                    )
                ) : (
                    <MenuSection
                        countryCode={countryCode}
                        title={t(OperatorProfilePhrasesEnum.Menu)}
                        items={emptyHeaderItems}
                    />
                )}
            </Widget>
            {tabsContent.length > 0 && (
                <Button onClick={scrollToMenuTop} className="MenuTabs__back-to-top">
                    <IconDoubleArrow className="Collapse__double-arrow" />
                </Button>
            )}
        </>
    );
};

export default Menu;
