import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { Snackbar } from '@mui/material';

import { MIMETypes } from '@core/utils/file';
import { useLocale } from '@core/utils/locale';
import { IconInformation } from '@assets/icons';
import { ExportPhrasesEnum } from '@core/enums/localeEnum';
import { OperatorFilter } from '@graphql/generated/graphql';
import useExportOperators from '@core/api/useExportOperators';
import { ExportModal } from '@pages/OperatorTargetingResults/components';

type ExportOperatorsProviderType = {
    isExportLoading: boolean;
    fetchFilteredOperators: (filters: OperatorFilter, exportType: MIMETypes) => void;
};

export const ExportOperatorsContext = createContext<ExportOperatorsProviderType | undefined>(undefined);

export function ExportOperatorsProvider({ children }: { children: ReactNode }) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);

    const { t } = useLocale();

    const {
        doFetchAndDownload,
        isExportLoading,
        hasExported,
        onCancelExport
    } = useExportOperators();

    const fetchFilteredOperators: ExportOperatorsProviderType['fetchFilteredOperators'] = useCallback(
        (filters, exportType) => {
            doFetchAndDownload(filters, exportType);
            setIsSnackbarOpen(true);
        },
        [doFetchAndDownload, setIsSnackbarOpen],
    );

    const handleSnackbarVisibility = useCallback(() => {
        if (isOpen) {
            return setIsSnackbarOpen(false);
        }

        if (isExportLoading) {
            return setIsSnackbarOpen(true);
        }

        if (hasExported) {
            return setTimeout(() => setIsSnackbarOpen(false), 1000);
        }

        return setIsSnackbarOpen(false);
    }, [hasExported, isExportLoading, isOpen, setIsSnackbarOpen]);

    useEffect(() => {
        handleSnackbarVisibility();
    }, [handleSnackbarVisibility]);

    return (
        <ExportOperatorsContext.Provider
            value={{
                isExportLoading,
                fetchFilteredOperators,
            }}
        >
            {children}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={isSnackbarOpen}
                message={hasExported ? t(ExportPhrasesEnum.ProcessingComplete) : t(ExportPhrasesEnum.ProcessingExport)}
                action={
                    <IconInformation
                        style={{
                            height: '28px',
                            width: '28px',
                        }}
                    />
                }
                onClick={() => {
                    setIsOpen(true);
                }}
            />
            <ExportModal
                isOpen={isOpen}
                hasExported={hasExported}
                onCancelExport={onCancelExport}
                handleClose={() => {
                    setIsOpen(false);
                }}
            />
        </ExportOperatorsContext.Provider>
    );
}

export function useExportOperatorsContext() {
    const context = useContext(ExportOperatorsContext);
    if (context === undefined) {
        throw new Error('useExportOperatorsContext must be used within a ExportOperatorsProvider');
    }
    return context;
}
