import cn from 'classnames';

import { FCX } from '@core/models';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import HighlightTextMatches from '@components/ui/Typography/HighlightTextMatches';

import '../Chip.styles.scss';

const Tag: FCX<{
    baseColor?: GradientColorsEnum;
    name: string;
    isChecked?: boolean;
    highlight?: string;
    onChange?: () => void;
    isGradient?: boolean;
}> = ({
    baseColor = GradientColorsEnum.ORANGE,
    name,
    isChecked = false,
    highlight = '',
    onChange,
    testId,
    isGradient = false,
}) => {
    return (
        <div
            className={cn('Chip Chip--tag', `is-${baseColor}`, { 'is-active': isChecked, 'is-gradient': isGradient })}
            onClick={onChange}
            data-testid={`tag${testId}`}
        >
            <div className="Chip__name">
                {highlight ? <HighlightTextMatches text={name} highlight={highlight} /> : name}
            </div>
        </div>
    );
};

export default Tag;
