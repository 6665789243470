import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import { ID } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { IconArrowCollapse } from '@assets/icons';
import { useAdvancedSearch } from '@core/contexts';
import Tag from '@components/utility/Chips/Tag/Tag';
import { toggleInArray } from '@core/helpers/helpers';
import Button from '@components/utility/Button/Button';
import Input from '@components/utility/Inputs/Input/Input';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import NoResultsFound from '@components/utility/Inputs/NoResultsFound';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import { containsSubstring, generateAnchorId } from '@core/utils/string';
import { CriterionEnum, CriterionParamFilter } from '@core/models/Criterion';
import { TagData, TagsCategoryEnum, TagsResponseContent } from '@core/api/useTags';
import { DropdownOption } from '@components/utility/Inputs/Dropdowns/Dropdowns.helpers';
import { CommonPhrasesEnum, OperatorProfilePhrasesEnum, FilterTypeEnum } from '@core/enums/localeEnum';

function mapTagDataToDropdownOption(tag: TagData): DropdownOption {
    return {
        id: tag.code,
        value: tag.code,
        label: tag.description,
    };
}

function getCollapsedTagLimit(dimensions: DOMRect | undefined): number {
    const width = dimensions?.width ?? 0;
    const averageTagWidth = 115;
    return Math.floor((width / averageTagWidth) * 4);
}

function getCollapsed(tags: TagData[], isExpanded: boolean, dimensions: DOMRect | undefined): TagData[] {
    if (isExpanded) {
        return tags;
    }

    return tags.slice(0, getCollapsedTagLimit(dimensions));
}

const TagsCategoryList: FC<{
    tagsData: TagsResponseContent;
    tagSearchPlaceholder: string;
}> = ({
    tagsData,
    tagSearchPlaceholder
}) => {
    const root = document.getElementById('observer-root');
    const dimensions = root?.getBoundingClientRect();
    const [selectedTags, setSelectedTags] = useState<ID[]>([]);
    const [tagFilter, setTagFilter] = useState<string>('');
    const [filteredTags, setFilteredTags] = useState<TagData[]>(tagsData.children);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const { t } = useLocale();
    const {
        addCriterionOnOptionSelected,
        getCriterionByFilterKey,
        onResetCriteriaByFilterKey
    } = useAdvancedSearch();

    const tagCategory = useMemo(() => {
        if (tagsData.category === TagsCategoryEnum.FOOD) {
            return { phrase: OperatorProfilePhrasesEnum.FoodCuisine, paramFilter: CriterionParamFilter.FoodTags };
        }

        return { phrase: OperatorProfilePhrasesEnum.Place, paramFilter: CriterionParamFilter.PlaceTags };
    }, [tagsData.category]);

    useEffect(() => {
        const tagParams = getCriterionByFilterKey(CriterionEnum.Tags, tagCategory.paramFilter);

        const selectedTagParamsIds = tagsData.children
            .filter((tag) => {
                return tagParams?.some((tagParam) => tagParam?.value === tag.code);
            })
            .map((tag) => tag.code);

        setSelectedTags(selectedTagParamsIds);
    }, [getCriterionByFilterKey, tagCategory.paramFilter, tagsData.children]);

    const onAddTags = useCallback(
        (tagsList: TagData[]) => {
            tagsList?.forEach((tag) => {
                const tagPill: DropdownOption = mapTagDataToDropdownOption(tag);
                addCriterionOnOptionSelected(CriterionEnum.Tags, tagCategory.paramFilter)(tagPill);
            });
        },
        [addCriterionOnOptionSelected, tagCategory.paramFilter],
    );

    const onUpdateSelectedTags = useCallback(
        (tagId: ID) => {
            const newSelectedTags = toggleInArray(selectedTags, tagId);
            setSelectedTags(newSelectedTags);

            onResetCriteriaByFilterKey(CriterionEnum.Tags, tagCategory.paramFilter);

            const activeCriteriaTags = tagsData.children.filter((tag) => {
                return newSelectedTags.includes(tag.code);
            });

            onAddTags(activeCriteriaTags);
        },
        [onAddTags, onResetCriteriaByFilterKey, selectedTags, tagCategory.paramFilter, tagsData.children],
    );

    const handleFilterChange = useCallback(
        (filterTermValue: string) => {
            setTagFilter(filterTermValue);
            setFilteredTags(
                tagsData.children.filter((tag) => containsSubstring(tag?.description, filterTermValue, true)),
            );
        },
        [setTagFilter, setFilteredTags, tagsData.children],
    );

    const handleExpand = useCallback(() => {
        setIsExpanded(!isExpanded);
        setFilteredTags(
            tagsData.children.filter((tag) => tag?.description.toLowerCase().includes(tagFilter?.toLowerCase())),
        );
    }, [isExpanded, setIsExpanded, tagFilter, tagsData.children]);

    return (
        <div className="CriteriaContent__section" id={generateAnchorId(t(tagCategory.phrase))} data-observable>
            <Widget type={WidgetType.Collapse} className="criteria">
                <div className="CriteriaContent__heading CriteriaContent__heading--section">
                    {t(tagCategory.phrase)}
                </div>

                <div className="TagsSearchBar__search-wrapper">
                    <Input
                        value={tagFilter}
                        onChange={handleFilterChange}
                        placeholder={tagSearchPlaceholder}
                        withClearIcon
                        withSearchIcon
                        testId="FoodTagFilterInput"
                    />
                </div>

                {filteredTags.length > 0 ? (
                    <>
                        <div className={'CriteriaContent__columns col-header'}>
                            <div className="TagsSearchBar__divider">
                                <div className="TagsSearchBar__heading">{t(FilterTypeEnum.Tags)}</div>
                                {getCollapsedTagLimit(dimensions) < filteredTags.length && (
                                    <Button
                                        onClick={handleExpand}
                                        modifiers={['naked', 'auto']}
                                        testId="TagsCategoryShowAll"
                                    >
                                        {isExpanded ? t(CommonPhrasesEnum.CollapseAll) : t(CommonPhrasesEnum.ShowAll)}
                                    </Button>
                                )}
                            </div>
                            <div className={'CriteriaContent__column col-1'}>
                                <div className="TagsContent__tag-container">
                                    {getCollapsed(filteredTags, isExpanded, dimensions).map((tag) => (
                                        <Tag
                                            key={tag.code}
                                            name={tag.description}
                                            highlight={tagFilter}
                                            testId={tag.description}
                                            isChecked={selectedTags.includes(tag.code)}
                                            baseColor={GradientColorsEnum.ORANGE}
                                            onChange={() => {
                                                onUpdateSelectedTags(tag.code);
                                            }}
                                            isGradient
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        {getCollapsedTagLimit(dimensions) < filteredTags.length && (
                            <div className="TagsContent__collapse-container">
                                <div
                                    onClick={handleExpand}
                                    className={cn('TagsContent__collapse-toggle', {
                                        'TagsContent__collapse-toggle--closed': isExpanded,
                                    })}
                                >
                                    <IconArrowCollapse />
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <div className="TagsContent__tags-not-found">
                        <NoResultsFound />
                    </div>
                )}
            </Widget>
        </div>
    );
};

export default TagsCategoryList;
