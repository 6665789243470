import React from 'react';
import cn from 'classnames';

import { useLocale } from '@core/utils/locale';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import Button from '@components/utility/Button/Button';
import Input from '@components/utility/Inputs/Input/Input';
import PageTitle from '@components/ui/PageTitle/PageTitle';
import FlagIcon from '@components/utility/FlagIcon/FlagIcon';
import TerritoryDrawer from '@components/features/TerritoryDrawer';
import SelectCountryModal from '@components/features/SelectCountryModal';
import { OperatorTargetingPhrasesEnum, TerritoriesPhrasesEnum } from '@core/enums/localeEnum';

import './Territories.styles.scss';
import useTerritoriesPage from './hooks/useTerritoriesPage';
import TerritoriesTable from './components/TerritoriesTable/TerritoriesTable';

const TerritoryImport: React.FC = () => {
    const { t } = useLocale();

    const {
        targetTerritoriesAndNavigate,
        isTerritoriesLoading,
        territorySelection,
        territoriesList,
        countryModal,
        drawer,
        search,
        filter,
    } = useTerritoriesPage();

    if (!countryModal.selectedCountry) {
        return null;
    }

    return (
        <div className="Territories">
            <PageTitle
                testId="TerritoriesPageTitle"
                title={t(TerritoriesPhrasesEnum.Territories)}
                rowContent={
                    <div className="Territories__header">
                        <div
                            className={cn('Territories__country-selector', {
                                disabled: countryModal.countriesList.length === 1,
                            })}
                            onClick={() => {
                                if (countryModal.countriesList.length === 1) {
                                    return;
                                }

                                countryModal.setIsModalOpen(true);
                            }}
                        >
                            <FlagIcon countryCode={countryModal.selectedCountry.code} height={16} />
                        </div>

                        <div className="Territories__header-buttons">
                            <Button
                                modifiers={['tertiary', 'auto']}
                                testId="buttonStartTargeting"
                                onClick={() => targetTerritoriesAndNavigate(RoutesEnum.OperatorTargetingCriteria)}
                                isDisabled={territorySelection.selectedTerritoryIds.length === 0}
                                style={{ backgroundColor: 'white' }}
                            >
                                {t(TerritoriesPhrasesEnum.StartTargeting)}
                            </Button>

                            <Button
                                modifiers={['primary', 'auto']}
                                testId="buttonSeeTerritoryResults"
                                onClick={() => targetTerritoriesAndNavigate(RoutesEnum.OperatorTargetingResults)}
                                isDisabled={territorySelection.selectedTerritoryIds.length === 0}
                            >
                                {t(OperatorTargetingPhrasesEnum.SeeResults)}
                            </Button>
                        </div>
                    </div>
                }
            />

            <div className="Territories__search">
                <Input
                    value={search.territoriesSearch}
                    onChange={search.setTerritoriesSearch}
                    placeholder={t(TerritoriesPhrasesEnum.SearchForATerritory)}
                    withClearIcon
                    withSearchIcon
                    testId="TerritoriesFilterInput"
                />
            </div>

            <TerritoriesTable
                territories={territoriesList}
                isTerritoriesLoading={isTerritoriesLoading}
                territorySelection={territorySelection}
                onOpenTerritoryDrawer={drawer.onOpenTerritoryDrawer}
                filter={filter}
            />

            <SelectCountryModal
                isOpen={countryModal.isModalOpen}
                onChangeCountry={countryModal.handleCountryChange}
                handleClose={() => countryModal.setIsModalOpen(false)}
                countries={countryModal.countriesList}
                selectedCountry={countryModal.selectedCountry}
            />

            <TerritoryDrawer
                isOpen={drawer.isDrawerOpen}
                handleClose={drawer.onCloseTerritoryDrawer}
                territory={drawer.activeTerritoryDrawer}
            />
        </div>
    );
};

export default TerritoryImport;
