import { FC } from 'react';

import Table from '@components/utility/Table/Table';
import { TerritoryDTO } from '@core/api/useTerritories';
import { TableProvider } from '@components/utility/Table/TableContext';

import { useTerritoriesTableConfig } from './useTerritoriesTableConfig';
import { TerritoriesColumnFilter, TerritorySelection } from '../../hooks/useTerritoriesPage';

const TerritoriesTable: FC<{
    territories: TerritoryDTO[];
    isTerritoriesLoading: boolean;
    territorySelection: TerritorySelection;
    onOpenTerritoryDrawer: (dto: TerritoryDTO) => void;
    filter: TerritoriesColumnFilter;
}> = ({
    filter,
    territories,
    isTerritoriesLoading,
    territorySelection,
    onOpenTerritoryDrawer
}) => {
    const {
        config,
        sortConfig,
        sortedData
    } = useTerritoriesTableConfig(
        territories,
        territorySelection,
        onOpenTerritoryDrawer,
        filter,
    );

    return (
        <TableProvider sortConfig={sortConfig} config={config}>
            <Table rowsCount={sortedData?.length ?? 0} testId={'TerritoriesTable'} loading={isTerritoriesLoading} />
        </TableProvider>
    );
};

export default TerritoriesTable;
