import { ID } from '@core/models';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { LocalesEnum } from '@core/enums/localeEnum';
import { CountryCodesEnum } from '@core/enums/flagsEnum';

export function equalInLC(string1?: string, string2?: string) {
    if (string1 === string2) return true;
    if (!string1 || !string2) return false;
    return string1.toLowerCase() === string2.toLowerCase();
}

export function prepareForMatching(str: string) {
    return str.trim().replace(/\s+/gm, ' ').toLowerCase();
}

export function containsSubstring(str: string, search: string, sanitized = false) {
    return prepareForMatching(sanitized ? removeAccents(str) : str).includes(
        prepareForMatching(sanitized ? removeAccents(search) : search),
    );
}

export function getCountryCodeFromLocale(locale: LocalesEnum): CountryCodesEnum {
    return locale.split('_')[1] as CountryCodesEnum;
}

export function replaceRouteParam(route: RoutesEnum, routeParamKey: string, param: string): string {
    return route.replace(routeParamKey, param);
}

export function findPatternIndex(inputString: string, pattern: string): number | null {
    const regex = new RegExp(pattern, 'i'); // 'i' flag for case-insensitivity
    const match = regex.exec(inputString);

    if (match) {
        return match.index;
    }
    return inputString.search(pattern);
}

export function capitalize(str: string, capsAll?: boolean) {
    return capsAll
        ? str.toUpperCase()
        : str
            .split(' ')
            .map((word) => {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            })
            .join(' ');
}

export function truncateString(str: string, maxLength: number): string {
    if (str.length > maxLength) {
        return str.slice(0, maxLength) + '...';
    } else {
        return str;
    }
}

export function removeAccents(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function escapeRegExpSpecialChars(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function formatPermissionString(str: string): string {
    const newStr = removeAccents(str);
    return newStr.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
}

export function generateAnchorId(str?: string, prefix?: string): string {
    if (!str) return '';

    const parsed = str
        .toLowerCase()
        .replace(/[^\w]+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');

    return prefix ? `${prefix}${parsed}` : parsed;
}

export function removeWhitespaces(str: string): string {
    return str.replace(/\s/g, '');
}

// This will take the ISO format date and convert it into MM-DD-YYYY format
export const formatDateISOtoDMY = (dateString: string, locale: LocalesEnum) => {
    const localeString = locale.toString().replace('_', '-');
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString(localeString, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    return formattedDate;
};

// This function stringifies the object and then removes the keys' quotes
export function stringifyAndRemoveQuotesFromKeys(obj: object) {
    const cleaned = JSON.stringify(obj, null, 2);

    return cleaned.replace(/^[\t ]*"[^:\n\r]+(?<!\\)":/gm, function (match) {
        return match.replace(/"/g, '');
    });
}

// This function stringifies the object queries where the graphQL query is sent on the body as text
// Since the API doesn't accept the quotes on ENUM values, this function removes them
// The ENUM values are hardcoded since they are known
export function stringifyObjectForGraphQL(obj: object) {
    const stringifiedQuery = stringifyAndRemoveQuotesFromKeys(obj);
    const unquotedQueryEnums = stringifiedQuery.replace(/"UNIT"|"HIGH"|"MEDIUM"|"LOW"/g, (match) =>
        match.replace(/"/g, ''),
    );

    return unquotedQueryEnums;
}

export const getRegexMatchingConfig = (
    str: string,
    patterns: string | string[],
    config: {
        isGlobal?: boolean;
        removeAccents: boolean;
        caseSensitive?: boolean;
        regexLookAhead?: string;
        regexLookBehind?: string;
    },
) => {
    const matchStr = config.removeAccents ? removeAccents(str) : str;
    const parsedPatterns = Array.isArray(patterns)
        ? patterns.map((pattern) => (config.removeAccents ? removeAccents(pattern) : pattern))
        : [config.removeAccents ? removeAccents(patterns) : patterns];

    const patternsMatch = `(${parsedPatterns.map(escapeRegExpSpecialChars).join('|')})`;

    const regex = new RegExp(
        `${config.regexLookBehind ?? ''}${patternsMatch}${config.regexLookAhead ?? ''}`,
        `${config.isGlobal ? 'g' : ''}${config.caseSensitive ? '' : 'i'}`,
    );

    return { matchStr, regex };
};

export type SplitParts = Array<{ id: number; text: string; isMatch: boolean }>;
export function splitTextOnMatches(str: string, regex: RegExp, matchStr?: string): SplitParts {
    const returnVal: SplitParts = [];
    let idCounter = 0;
    let lastIndex = 0;

    let match;
    while ((match = regex.exec(matchStr ?? str)) !== null) {
        const firstIndex = match.index;
        const matchedText = match[0];

        if (firstIndex > lastIndex) {
            returnVal.push({ id: idCounter++, text: str.slice(lastIndex, firstIndex), isMatch: false });
        }

        returnVal.push({
            id: idCounter++,
            text: str.slice(firstIndex, firstIndex + matchedText.length),
            isMatch: true,
        });
        lastIndex = firstIndex + matchedText.length;

        if (!regex.global) {
            break;
        }

        if (matchedText.length === 0) {
            regex.lastIndex++;
        }
    }

    if (lastIndex < (matchStr ?? str).length) {
        returnVal.push({ id: idCounter++, text: str.slice(lastIndex), isMatch: false });
    }

    return returnVal;
}

export function formatTestIds(id?: ID) {
    if (!id) return '';

    return id
        .toString()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join('');
}

export function formatNumberByLocale(number: number, locale: LocalesEnum): string {
    return number.toLocaleString(locale.substring(3, 5), {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
}
