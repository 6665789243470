import { FC } from 'react';
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableContainer from '@mui/material/TableContainer';

import Widget, { WidgetType } from '@components/utility/Widget/Widget';

import './Table.styles.scss';
import TableRow from './TableRow';
import TableHead from './TableHead';
import TableFeedback from './TableFeedback/TableFeedback';

const Table: FC<{
    rowsCount: number;
    loading: boolean;
    testId?: string;
}> = ({
    loading,
    rowsCount,
    testId
}) => {
    return (
        <div className="Table">
            <TableContainer component={(props) => <Widget type={WidgetType.Table} {...props} />}>
                <MuiTable stickyHeader={true} aria-label="Table" data-testid={testId ?? ''}>
                    <MuiTableHead>
                        <TableHead />
                    </MuiTableHead>
                    {!loading && (
                        <MuiTableBody sx={{ position: 'relative' }}>
                            {new Array(rowsCount).fill(0).map((_, index) => (
                                <TableRow key={index} rowIndex={index} />
                            ))}
                        </MuiTableBody>
                    )}
                    <TableFooter>
                        <tr>
                            <td colSpan={100} style={{ padding: '0px' }}>
                                <TableFeedback isLoading={loading} isEmpty={rowsCount === 0} />
                            </td>
                        </tr>
                    </TableFooter>
                </MuiTable>
            </TableContainer>
        </div>
    );
};

export default Table;
