import streamSaver from 'streamsaver';
import { AxiosResponse, AxiosHeaders } from 'axios';
import { WritableStream } from 'web-streams-polyfill';

const UTF8_BOM = '\uFEFF';

export enum MIMETypes {
    CSV = 'text/csv; charset=utf-8',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

type NavigatorIncludesMS = Navigator & { msSaveBlob?: (blob: Blob, filename?: string) => void };

export function downloadFile(url: string, filename?: string): void {
    const link = document.createElement('a');

    if (typeof link.download === 'undefined') {
        window.location.href = url;
    } else {
        link.href = url;
        link.download = filename || 'true';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export function downloadBlobFile(blob: Blob | null, filename?: string) {
    if (!blob) return;

    const navigator = window.navigator as NavigatorIncludesMS;

    if (navigator.msSaveBlob && typeof navigator.msSaveBlob !== 'undefined') {
        navigator.msSaveBlob(blob, filename);
    } else {
        const URL = window.URL || window.webkitURL;
        const downloadUrl = URL.createObjectURL(blob);

        if (filename) {
            downloadFile(downloadUrl, filename);
        } else {
            window.location.href = downloadUrl;
        }

        setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
        }, 100);
    }
}

export function getFilenameFromContentDisposition(disposition: string | null): string {
    let filename = '';
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = new RegExp(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        const matches = disposition.match(filenameRegex);
        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
    }

    return filename;
}

export function getBlobFromMimeType(data: BlobPart | string, mimeType: MIMETypes): Blob | null {
    try {
        switch (mimeType) {
            case MIMETypes.CSV:
                return new Blob([UTF8_BOM + data], {
                    type: 'text/csv; charset=utf-8',
                });

            case MIMETypes.XLSX:
                return new Blob([data]);

            default:
                return new Blob([data], {
                    type: 'text/plain',
                });
        }
    } catch (error) {
        console.error('Error creating Blob:', error);
        return null;
    }
}

export function getFileExtensionFromMimeType(mimeType: MIMETypes): string {
    switch (mimeType) {
        case MIMETypes.CSV:
            return 'csv';

        case MIMETypes.XLSX:
            return 'xlsx';

        default:
            return 'txt';
    }
}

export function getFilenameFromResponse(response: AxiosResponse<BlobPart> | Response, fallbackFilename = ''): string {
    const disposition = (response.headers as AxiosHeaders).get('Content-Disposition') || '';
    return getFilenameFromContentDisposition(disposition as string) || fallbackFilename;
}

function setupFileStream(filename: string) {
    // If the WritableStream is not available (Firefox, Safari), take it from the ponyfill
    if (!window.WritableStream) {
        streamSaver.WritableStream = WritableStream;
        window.WritableStream = WritableStream;
    }

    return streamSaver.createWriteStream(filename);
}

/**
 * Prepends a UTF-8 Byte Order Mark (BOM) to a given ReadableStream.
 * This is useful for ensuring that CSV files are correctly recognized by Excel.
 *
 * @param readableStream - The original ReadableStream to which the BOM should be prepended.
 * @returns A new ReadableStream with the BOM prepended.
 */
function prependBomToReadableStream(readableStream: ReadableStream<Uint8Array>) {
    return new ReadableStream({
        start(controller) {
            controller.enqueue(new TextEncoder().encode(UTF8_BOM));
            const reader = readableStream.getReader();
            function push() {
                reader.read().then((result) => {
                    if (result.done) {
                        controller.close();
                        return;
                    }
                    controller.enqueue(result.value);
                    push();
                });
            }
            push();
        },
    });
}

function exportStreamDownload(filename: string, exportFormat: MIMETypes, body: Response['body']) {
    if (!body) return console.error('ReadableStream is not available');

    const fileStream = setupFileStream(filename);
    const readableStream = exportFormat === MIMETypes.XLSX ? body : prependBomToReadableStream(body);

    // Preferred if WritableStream and pipeTo are available
    if (window?.WritableStream && readableStream?.pipeTo) {
        return readableStream.pipeTo(fileStream);
    }

    const writer = fileStream.getWriter();
    const reader = readableStream.getReader();

    const pump = (): Promise<void> => {
        return reader.read().then((res) => {
            if (res.done) {
                return writer.close();
            }

            return writer.write(res.value).then(pump);
        });
    };

    return pump();
}

export async function downloadExport(
    response: AxiosResponse<BlobPart> | Response,
    exportFormat: MIMETypes,
    fallbackFilename: string,
) {
    const filename = getFilenameFromResponse(response, fallbackFilename);

    if (response instanceof Response) {
        return exportStreamDownload(filename, exportFormat, response.body);
    }

    const blob = getBlobFromMimeType(response.data, exportFormat);
    return downloadBlobFile(blob, filename);
}
