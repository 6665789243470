import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

export function toggleInArray<T>(array: T[], ...values: T[]) {
    let result = [...array];
    if (values.length === 0) return [];
    values.forEach((value) => {
        if (array.includes(value)) {
            result = result.filter((item) => item !== value);
        } else {
            result = [...result, value];
        }
    });
    return result;
}

export function toggleObjectInArrayByKey<T extends { [key: string]: unknown }>(
    array: T[] = [],
    object: T | undefined,
    key: keyof T,
) {
    if (!object) return array;

    const index = array.findIndex((item) => item[key] === object[key]);

    if (index !== -1) {
        const newArray = [...array];
        newArray.splice(index, 1);
        return newArray;
    } else {
        return [...array, object];
    }
}

export function reorderDragAndDrop<T>(list: T[], startIndex: number, endIndex: number) {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    const listWithSelectedColumnOrder = result.map((item, index) => ({
        ...item,
        selectedColumnOrder: index,
    }));

    return listWithSelectedColumnOrder;
}

export function isEmpty(val: unknown) {
    if (typeof val === 'number') {
        return false;
    }

    return val == null || !(Object.keys(val) || val).length;
}

export function getClickableLink(link: string) {
    return link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
}

export function getFormattedPhoneNumber(phone: string, countryCode: string): string {
    const DEFAULT_PHONE_COUNTRY = 'US';
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
        const parseNumber = phone ? phoneUtil.parse(phone, countryCode ?? DEFAULT_PHONE_COUNTRY) : null;
        if (parseNumber) {
            return phoneUtil.format(parseNumber, PhoneNumberFormat.INTERNATIONAL);
        }
    } catch (error) {
        console.error('Error parsing phone number:', error);
    }
    return phone;
}

export function groupBy<T, K extends string>(array: T[], predicate: (value: T, index: number, array: T[]) => K) {
    return array.reduce(
        (acc, value, index, array) => {
            (acc[predicate(value, index, array)] ||= []).push(value);
            return acc;
        },
        {} as Record<K, T[]>,
    );
}

export function getPercentageOfTotal(total: number, value: number): string {
    return total === 0 ? '0.0%' : ((value / total) * 100).toFixed(1) + '%';
}

export function getNumericPercentageOfTotal(total: number, value: number): number {
    return total === 0 ? 0 : (value / total) * 100;
}

export function arraysAreEqualUnordered(arr1: (string | number)[], arr2: (string | number)[]): boolean {
    if (arr1.length !== arr2.length) return false;
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) return false;
    }
    return true;
}
