import { FC, useEffect, useMemo } from 'react';
import cn from 'classnames';

import useFields from '@core/api/useFields';
import { useLocale } from '@core/utils/locale';
import Drawer from '@components/utility/Drawer/Drawer';
import { ParsedTerritoryDTO } from '@core/api/useTerritories';
import { CriterionParamFilter } from '@core/models/Criterion';
import { PhrasesEnum, TerritoriesPhrasesEnum } from '@core/enums/localeEnum';
import { getTerritoryHierarchy } from '@pages/Territories/hooks/useTerritoriesPage';
import { LocationFieldsEnum } from '@pages/OperatorTargetingCriteria/hooks/useLocationCriteria';

import './TerritoryDrawer.styles.scss';

const mapTerritoryDetailsToPhrases: Record<string, PhrasesEnum | string> = {
    [CriterionParamFilter.PostalCode]: TerritoriesPhrasesEnum.PostalCodesIncluded,
    [CriterionParamFilter.AdministrativeArea1]: LocationFieldsEnum.ADMIN_AREA_1,
    [CriterionParamFilter.AdministrativeArea2]: LocationFieldsEnum.ADMIN_AREA_2,
};

const TerritoryDrawer: FC<{
    isOpen: boolean;
    handleClose: () => void;
    territory: ParsedTerritoryDTO | null;
}> = ({
    isOpen,
    handleClose,
    territory
}) => {
    const {
        t,
        locale
    } = useLocale();

    const {
        data: fieldsData,
        loading,
        doFetch
    } = useFields();

    useEffect(() => {
        if (territory?.country) {
            doFetch({
                params: {
                    country: territory?.country,
                    type: 'LOCATION',
                    locale,
                },
            });
        }
    }, [doFetch, locale, territory?.country]);

    const renderTerritoryDetails = useMemo(() => {
        if (!territory || loading) {
            return null;
        }

        const parsedDetails: Array<{
            criterion: PhrasesEnum | string;
            values: string[];
        }> = Object.entries(territory?.details ?? {}).flatMap(([key, values]) => {
            const detailPhrase = mapTerritoryDetailsToPhrases[key];

            return detailPhrase ? { criterion: detailPhrase, values } : [];
        });

        let hasNotSearchableDetail = false;
        const content = parsedDetails.map((detail) => {
            const fieldLabel =
                fieldsData?.find((f) => f?.type === detail.criterion)?.label ?? t(detail.criterion as PhrasesEnum);
            const isNotSearchable = fieldLabel.includes('_');
            if (isNotSearchable) {
                hasNotSearchableDetail = isNotSearchable;
            }

            return (
                <div key={detail.criterion} className="TerritoryDrawer__criterion">
                    <div
                        className={cn('TerritoryDrawer__criterion-name', {
                            'TerritoryDrawer__criterion-name--not-searchable': isNotSearchable,
                        })}
                    >
                        {fieldLabel + (isNotSearchable ? '*' : '')}
                    </div>
                    <div className="TerritoryDrawer__criterion-grid">
                        {detail.values.map((value) => (
                            <div className="TerritoryDrawer__criterion-value" key={value}>
                                {value}
                            </div>
                        ))}
                    </div>
                </div>
            );
        });

        return {
            content,
            header: (
                <span className="TerritoryDrawer__group-name">{territory && getTerritoryHierarchy(territory)}</span>
            ),
            footer: hasNotSearchableDetail ? (
                <span className="TerritoryDrawer__footer">{t(TerritoriesPhrasesEnum.FieldNotSearchable)}</span>
            ) : null,
        };
    }, [fieldsData, loading, t, territory]);

    return (
        <Drawer
            className="TerritoryDrawer"
            isOpen={isOpen}
            onClose={handleClose}
            title={territory?.name}
            headerContent={renderTerritoryDetails?.header}
            footerContent={renderTerritoryDetails?.footer}
        >
            <div className="TerritoryDrawer__content">{renderTerritoryDetails?.content}</div>
        </Drawer>
    );
};

export default TerritoryDrawer;
