import { useState, ReactNode, ComponentPropsWithoutRef, useEffect } from 'react';
import { FCX } from '@core/models';

import cn from 'classnames';
import MuiCollapse from '@mui/material/Collapse';

import { IconArrowCollapse, IconDoubleArrow } from '@assets/icons';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';

import './Collapse.styles.scss';

const Collapse: FCX<{
    actionsSlot?: ReactNode;
    collapsedContentSlot?: ReactNode;
    collapsedSize?: string | number;
    children?: ReactNode;
    startCollapsed?: boolean;
    title?: string | ReactNode;
    widgetProps?: ComponentPropsWithoutRef<typeof Widget>;
    collapseType?: 'default' | 'bottom-arrow';
    onCollapseChange?: (isCollapsed: boolean) => void;
}> = ({
    actionsSlot,
    collapsedContentSlot,
    collapsedSize = '34px',
    children,
    startCollapsed = true,
    title,
    widgetProps,
    collapseType = 'default',
    testId,
    onCollapseChange,
}) => {
    const [isCollapsed, setIsCollapsed] = useState(startCollapsed);

    useEffect(() => {
        if (onCollapseChange) {
            onCollapseChange(isCollapsed);
        }
    }, [isCollapsed, onCollapseChange]);

    const onToggleCollapse = () => {
        setIsCollapsed((prevIsCollapse) => !prevIsCollapse);
    };

    const isDefaultCollapse = collapseType === 'default';
    const isBottomArrowCollapse = collapseType === 'bottom-arrow';

    return (
        <Widget type={WidgetType.Collapse} {...widgetProps}>
            <MuiCollapse in={!isCollapsed} timeout={300} collapsedSize={collapsedSize}>
                <div
                    className={cn('Collapse', {
                        Collapse__default: isDefaultCollapse,
                    })}
                    onClick={isDefaultCollapse ? onToggleCollapse : undefined}
                >
                    {title && <div className="Collapse__title">{title}</div>}
                    <div
                        className={cn('Collapse__collapsed-content', {
                            'Collapse__collapsed-content--expanded': !isCollapsed,
                            'Collapse__collapsed-content--bottom-arrow': isBottomArrowCollapse && !isCollapsed,
                        })}
                    >
                        {collapsedContentSlot}
                    </div>
                    {actionsSlot}
                    {isDefaultCollapse && (
                        <div
                            className={cn('Collapse__toggle', {
                                'Collapse__toggle--closed': isCollapsed,
                            })}
                        >
                            <IconArrowCollapse />
                        </div>
                    )}
                </div>
                {children}
            </MuiCollapse>
            {isBottomArrowCollapse && (
                <div
                    data-testid={testId}
                    className={cn('Collapse__collapsible', isCollapsed && 'Collapse__collapsible--collapsed')}
                    onClick={onToggleCollapse}
                >
                    <IconDoubleArrow className="Collapse__double-arrow" />
                </div>
            )}
        </Widget>
    );
};

export default Collapse;
