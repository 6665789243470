import dayjs from 'dayjs';
import { useMemo } from 'react';

import useTableSort, { SortOrder, useTableSortReturn } from './useTableSort';

const useSortedTableData = <T extends Record<string, unknown>>(
    data: T[],
    defaultSortField: string,
): [T[], useTableSortReturn] => {
    const {
        sortedColumns,
        onSort,
        onClearSort
    } = useTableSort(defaultSortField);

    const sortedData = useMemo(() => {
        if (sortedColumns.length === 0) {
            return data;
        }

        const {
            field,
            order
        } = sortedColumns[0];

        return [...data].sort((a, b) => {
            return order === SortOrder.Asc ? compareValues(a[field], b[field]) * -1 : compareValues(a[field], b[field]);
        });
    }, [data, sortedColumns]);

    return [sortedData, { sortedColumns, onSort, onClearSort }];
};

function compareValues(a: unknown, b: unknown): number {
    if (a === undefined || a === null) return 1;
    if (b === undefined || b === null) return -1;

    if (typeof a === 'string' && typeof b === 'string') {
        return compareStrings(a, b);
    }

    if (typeof a === 'number' && typeof b === 'number') {
        return b - a;
    }

    if (typeof a === 'boolean' && typeof b === 'boolean') {
        if (a === b) {
            return 0;
        }
        return a ? 1 : -1;
    }

    return 0; // If types are mixed or not handled, keep original order
}

export function compareStrings(a: string, b: string): number {
    const dateA = dayjs(a);
    const dateB = dayjs(b);

    if (dateA.isValid() && dateB.isValid()) {
        return dateA.diff(dateB);
    }

    return b.localeCompare(a);
}

export default useSortedTableData;
