import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ID } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { useAdvancedSearch } from '@core/contexts';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import Tooltip from '@components/utility/Tooltip/Tooltip';
import useAnchorElement from '@core/hooks/useAnchorElement';
import { ParsedTerritoryDTO } from '@core/api/useTerritories';
import useTerritoryFavorite from '@core/api/useTerritoryFavorite';
import { TableConfig } from '@components/utility/Table/Table.helpers';
import Toggle, { ToggleType } from '@components/utility/Toggle/Toggle';
import DropdownMenu from '@components/utility/DropdownMenu/DropdownMenu';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import InformationTooltip from '@components/utility/Tooltip/InformationTooltip';
import useSortedTableData, { compareStrings } from '@core/hooks/useSortedTableData';
import { IconInformation, IconMenuSettings, IconRatingStar, IconRatingStarEmpty } from '@assets/icons';
import { CommonPhrasesEnum, OperatorTargetingPhrasesEnum, TerritoriesPhrasesEnum } from '@core/enums/localeEnum';

import {
    getTerritoryCriteria,
    mapTerritoriesToCriterionParam,
    TerritoriesColumnFilter,
    TerritorySelection,
} from '../../hooks/useTerritoriesPage';

const TERRITORIES_DEFAULT_SORT = 'favorite';
const TERRITORIES_NAME_SORT = 'name';

export function useTerritoriesTableConfig(
    data: ParsedTerritoryDTO[],
    territorySelection: TerritorySelection,
    onOpenTerritoryDrawer: (dto: ParsedTerritoryDTO) => void,
    filter: TerritoriesColumnFilter,
): TableConfig & { sortedData: ParsedTerritoryDTO[] } {
    const parsedData = useMemo(() => {
        return data.sort((a, b) => compareStrings(b.name, a.name));
    }, [data]);

    const [sortedData, sortConfig] = useSortedTableData(parsedData, TERRITORIES_DEFAULT_SORT);
    const isTerritoiresPageFavoritesEnabled = useFeatureFlag(FeatureFlags.TerritoiresPageFavorites);

    const {
        t,
        locale
    } = useLocale();
    const navigate = useNavigate();

    const {
        activeCountry,
        onMergeCriteria
    } = useAdvancedSearch();

    const { doFetch } = useTerritoryFavorite();

    const onTargetTerritoryAndNavigate = useCallback(
        (dto: ParsedTerritoryDTO, route: RoutesEnum) => {
            if (!activeCountry) return;

            const territoryCriteria = getTerritoryCriteria([
                activeCountry,
                ...mapTerritoriesToCriterionParam([dto], locale),
            ]);

            onMergeCriteria(territoryCriteria);
            navigate(route, { state: { from: RoutesEnum.Territories } });
        },
        [activeCountry, navigate, locale, onMergeCriteria],
    );

    const onFavoriteTerritory = useCallback(
        (index: number, territoryId: string, newState: boolean) => {
            doFetch({
                territory_id: territoryId,
                favorite: newState,
            }).then((response) => {
                if (response?.status == 200) {
                    sortedData[index].favorite = !sortedData[index].favorite;
                }
            });
        },
        [doFetch, sortedData],
    );

    const getDropdownMenuOptions = useCallback(
        (dto: ParsedTerritoryDTO) => [
            {
                label: t(OperatorTargetingPhrasesEnum.SeeResults),
                onClick: () => onTargetTerritoryAndNavigate(dto, RoutesEnum.OperatorTargetingResults),
            },
            {
                label: t(TerritoriesPhrasesEnum.StartTargeting),
                onClick: () => onTargetTerritoryAndNavigate(dto, RoutesEnum.OperatorTargetingCriteria),
                withDivider: true,
            },
            {
                label: t(TerritoriesPhrasesEnum.ViewTerritory),
                onClick: () => onOpenTerritoryDrawer(dto),
            },
        ],
        [onOpenTerritoryDrawer, onTargetTerritoryAndNavigate, t],
    );

    return useMemo(
        () => ({
            config: [
                {
                    title: '',
                    renderTitle: (
                        <Toggle
                            type={ToggleType.Checkbox}
                            isChecked={sortedData?.length > 0 && territorySelection.isAllSelected}
                            setIsChecked={territorySelection.toggleAll}
                            style={{ height: 'auto' }}
                        />
                    ),
                    field: '',
                    Cell: ({ index }: { index: number }) => {
                        return (
                            <Toggle
                                type={ToggleType.Checkbox}
                                isChecked={territorySelection.isSelected(sortedData?.[index]?.name)}
                                setIsChecked={() => territorySelection.toggleById(sortedData?.[index]?.name)}
                                style={{ height: 'auto' }}
                            />
                        );
                    },
                    isSortable: false,
                    cellStyles: { width: '10px', paddingRight: '0' },
                },
                {
                    title: TerritoriesPhrasesEnum.TerritoryName,
                    field: TERRITORIES_NAME_SORT,
                    Cell: ({ index }) => {
                        return <strong style={{ color: '#00866F' }}>{sortedData?.[index]?.name}</strong>;
                    },
                    isSortable: true,
                    onClick: (index: ID) => {
                        onTargetTerritoryAndNavigate(
                            sortedData?.[index as number],
                            RoutesEnum.OperatorTargetingResults,
                        );
                    },
                },
                {
                    title: TerritoriesPhrasesEnum.Group,
                    // Although the it's for the group column, on the current iteration we will show the subgroup
                    field: 'subgroup',
                    Cell: ({ index }) => sortedData?.[index]?.subgroup ?? '',
                    isSortable: true,
                    filter: {
                        filterOptions: filter.groupsList,
                        onFilter: filter.onFilterColumns,
                        selectedOptions: filter.selectedGroups,
                    },
                    cellStyles: { width: '200px' },
                },
                ...(isTerritoiresPageFavoritesEnabled
                    ? [
                        {
                            title: TerritoriesPhrasesEnum.Favorite,
                            renderTitle: (
                                <>
                                    {t(TerritoriesPhrasesEnum.Favorite)}
                                    <Tooltip
                                        content={
                                            <InformationTooltip
                                                title=""
                                                description={t(CommonPhrasesEnum.TerritoryFavoriteToolTipDesc)}
                                            />
                                        }
                                        placement="bottom"
                                    >
                                        <IconInformation className="Territories__info-icon" />
                                    </Tooltip>
                                </>
                            ),
                            field: TERRITORIES_DEFAULT_SORT,
                            Cell: ({ index }: { index: number }) => {
                                return sortedData?.[index]?.favorite ? (
                                    <IconRatingStar key={index} className="Territories__favorite" />
                                ) : (
                                    <IconRatingStarEmpty key={index} className="Territories__favorite" />
                                );
                            },
                            isSortable: true,
                            onClick: (index: ID) => {
                                const indexNumber = index as number;
                                onFavoriteTerritory(
                                    indexNumber,
                                    sortedData?.[indexNumber]?.territory_external_id ?? '',
                                    !(sortedData?.[indexNumber]?.favorite ?? false),
                                );
                            },
                            cellStyles: { width: '50px' },
                        },
                    ]
                    : []),

                {
                    title: '',
                    field: '',
                    Cell: ({ index }) => {
                        const {
                            onAnchorClick,
                            ...rest
                        } = useAnchorElement();

                        return (
                            <>
                                <div onClick={onAnchorClick} className="KebabMenuIcon">
                                    <IconMenuSettings />
                                </div>
                                <DropdownMenu
                                    options={getDropdownMenuOptions(sortedData?.[index])}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    {...rest}
                                />
                            </>
                        );
                    },
                    isSortable: false,
                    cellStyles: { width: '56px' },
                },
            ],
            sortConfig,
            sortedData,
        }),
        [
            filter.groupsList,
            filter.onFilterColumns,
            filter.selectedGroups,
            getDropdownMenuOptions,
            isTerritoiresPageFavoritesEnabled,
            onFavoriteTerritory,
            onTargetTerritoryAndNavigate,
            sortConfig,
            sortedData,
            t,
            territorySelection,
        ],
    );
}
