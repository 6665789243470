import TerritorySwitchList from './TerritorySwitchList';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import useTerritories from '@core/api/useTerritories';
import './TerritoryFavoritesCriteria.styles.scss';
import { useEffect } from 'react';
import { CriterionParamFilter } from '@core/models/Criterion';
import { useNavigate } from 'react-router-dom';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { generateAnchorId } from '@core/utils/string';
import { TerritoriesPhrasesEnum } from '@core/enums/localeEnum';
import { useLocale } from '@core/utils/locale';

const TerritoryFavoritesCriteria: React.FC = () => {
    const { t } = useLocale();
    const navigate = useNavigate();

    const { activeCountry } = useAdvancedSearch();

    const {
        data,
        doFetch
    } = useTerritories();

    useEffect(() => {
        if (activeCountry?.value) {
            doFetch({ params: { country: activeCountry.value as string } });
        }
    }, [doFetch, activeCountry?.value]);

    const handleAddTerritoryClick = () => {
        navigate(RoutesEnum.Territories);
    };

    if (!data || data.territories?.length === 0) {
        return null;
    }

    return (
        <div className="TerritoryFavoritesCriteria" id={generateAnchorId(t(TerritoriesPhrasesEnum.TerritoryFavorites))}>
            <TerritorySwitchList
                territories={data?.territories ?? []}
                criterionFilterKey={CriterionParamFilter.Territories}
                testId="favorite-territories-switch-list"
                handleAddTerritoryClick={handleAddTerritoryClick}
            />
        </div>
    );
};

export default TerritoryFavoritesCriteria;
