import { useCallback } from 'react';

import useFetch from '@core/hooks/useFetch';
import { MethodsEnum } from '@core/enums/api';

const POST_TERRITORY_IMPORT = '/territories/import';

export default function useTerritoryImport() {
    const {
        loading,
        error,
        doFetch
    } = useFetch<{ messages?: string[] }, { territoryFile?: string }, FormData>({
        lazy: true,
        url: POST_TERRITORY_IMPORT,
        method: MethodsEnum.POST,
        initialConfig: {
            maxBodyLength: Infinity,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    });

    const doUploadFile = useCallback(
        (file: File, onSuccess: () => void) => {
            const formData = new FormData();
            formData.append('territoryFile', file);

            doFetch({
                data: formData,
            }).then(async (res) => {
                if (res?.status === 201) {
                    onSuccess();
                }
            });
        },
        [doFetch],
    );

    return {
        loading,
        error,
        doUploadFile,
    } as const;
}
