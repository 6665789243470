import { useMemo } from 'react';

import { ID } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { useAdvancedSearch } from '@core/contexts';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import Tag from '@components/utility/Chips/Tag/Tag';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { TableColumnsEnum } from '@core/enums/localeEnum';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import { SearchOperatorsQuery } from '@graphql/generated/graphql';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import { getCountryCodeFromLocale, replaceRouteParam } from '@core/utils/string';
import useCustomerMetadata, { CustomerMetadataTypeEnum } from '@core/api/useCustomerMetadata';
import { TableConfig, TableConfigItem, buildOperatorAddress } from '@components/utility/Table/Table.helpers';

export type OperatorTargetingData = SearchOperatorsQuery['search'];

export const OP_TARGETING_RESULTS_TABLE_TEST_ID = 'operatorTargetingResultsTable';
export const OP_TARGETING_DEFAULT_SORT_FIELD = 'name.normalized';

export function getOperatorResultsTableConfig(
    data: OperatorTargetingData,
    countryLocale: CountryCodesEnum = CountryCodesEnum.US,
    sortConfig: ReturnType<typeof useAdvancedSearch>['advancedSearchSort'],
    isCustomerStatusTableEnabled = false,
): TableConfig {
    const operators = data?.operators;

    const rowConfig = {
        anchorHref: (index: ID) => {
            return replaceRouteParam(
                RoutesEnum.OperatorProfile,
                ':id',
                operators?.[index as number]?.operator_identifier ?? '',
            );
        },
    };

    return {
        sortConfig,
        rowConfig,
        config: [
            {
                title: TableColumnsEnum.Name,
                field: OP_TARGETING_DEFAULT_SORT_FIELD,
                Cell: ({ index }) => {
                    return <strong>{operators?.[index]?.business_name?.toUpperCase()}</strong>;
                },
                isSortable: true,
                cellStyles: { width: '100%' },
            },
            {
                title: TableColumnsEnum.Address,
                field: 'address',
                Cell: ({ index }) => (
                    <>{buildOperatorAddress(operators?.[index]?.location, operators?.[index]?.phone)}</>
                ),
                isSortable: false,
                cellStyles: { minWidth: '180px' },
            },
            {
                title: TableColumnsEnum.Segment,
                field: `segment.name.${countryLocale}.keyword`,
                Cell: ({ index }) => <>{operators?.[index]?.segment}</>,
                isSortable: true,
                cellStyles: { minWidth: '120px' },
            },
            {
                title: TableColumnsEnum.Cuisine,
                field: `cuisine.name.${countryLocale}.keyword`,
                Cell: ({ index }) => <>{operators?.[index]?.sub_cuisine}</>,
                isSortable: true,
                cellStyles: { minWidth: '110px' },
            },
            {
                title: TableColumnsEnum.TotalUnits,
                field: 'number_of_units_in_chain',
                align: 'right',
                Cell: ({ index }) => <>{operators?.[index]?.parent_chain?.number_of_units ?? 1}</>,
                isSortable: true,
                cellStyles: { minWidth: '102px' },
            },
            ...(isCustomerStatusTableEnabled
                ? [
                      {
                          title: TableColumnsEnum.ClientStatus,
                          field: 'customer_status',
                          Cell: ({ index }) => {
                              const status = operators?.[index]?.company_data?.status ?? '';
                              if (status) {
                                  return (
                                      <div className="flex">
                                          <Tag
                                              name={status}
                                              testId="CustomerStatus"
                                              baseColor={GradientColorsEnum.PURPLE}
                                              isGradient
                                              isChecked
                                          />
                                      </div>
                                  );
                              }

                              return null;
                          },
                          isSortable: true,
                          cellStyles: { minWidth: '182px' },
                      } as TableConfigItem,
                ]
                : []),
        ],
    };
}

export default function useOperatorResultsTable(data: OperatorTargetingData): TableConfig {
    const { locale } = useLocale();
    const { advancedSearchSort } = useAdvancedSearch();
    const localeCountryCode = getCountryCodeFromLocale(locale);
    const isCustomerStatusTableEnabled = useFeatureFlag(FeatureFlags.CustomerStatusTable);
    const { error } = useCustomerMetadata({ customer_metadata_type: CustomerMetadataTypeEnum.STATUS });

    const shouldShowCustomerStatusTable = !error && isCustomerStatusTableEnabled;

    return useMemo(
        () => getOperatorResultsTableConfig(data, localeCountryCode, advancedSearchSort, shouldShowCustomerStatusTable),
        [data, localeCountryCode, advancedSearchSort, shouldShowCustomerStatusTable],
    );
}
