import { ReactNode } from 'react';
import MuiDrawer from '@mui/material/Drawer';

import { FCX } from '@core/models';
import CloseButton from '@components/utility/Button/CloseButton/CloseButton';

import './Drawer.styles.scss';

export enum DrawerPlacement {
    Left = 'left',
    Right = 'right',
    Top = 'top',
    Bottom = 'bottom',
}

const Drawer: FCX<{
    children?: ReactNode;
    footerContent?: ReactNode;
    headerContent?: ReactNode;
    placement?: DrawerPlacement;
    title?: string | ReactNode;
    isOpen: boolean;
    onClose: () => void;
}> = ({
    children,
    className,
    placement = DrawerPlacement.Right,
    isOpen = false,
    onClose,
    footerContent,
    headerContent,
    title,
}) => {
    return (
        <MuiDrawer anchor={placement} open={isOpen} onClose={onClose} classes={{ paper: className }} className="Drawer">
            <div className="Drawer__header">
                <div className="Drawer__title heading-2">{title}</div>
                <div className="Drawer__close">
                    <CloseButton onClick={onClose} />
                </div>
                <div className="Drawer__content">{headerContent}</div>
            </div>
            <div className="Drawer__body">{children}</div>
            {footerContent && <div className="Drawer__footer">{footerContent}</div>}
        </MuiDrawer>
    );
};

export default Drawer;
