import { useState, useCallback, useMemo } from 'react';
import { MenuItem, MenuList } from '@mui/material';

import { useLocale } from '@core/utils/locale';
import { FCX, ID, iOption } from '@core/models';
import { useInput } from '@core/hooks/useInput';
import { containsSubstring } from '@core/utils/string';
import '@components/utility/Inputs/Inputs.styles.scss';
import Button from '@components/utility/Button/Button';
import Input from '@components/utility/Inputs/Input/Input';
import NoResultsFound from '@components/utility/Inputs/NoResultsFound';
import Toggle, { ToggleType } from '@components/utility/Toggle/Toggle';
import { arraysAreEqualUnordered, toggleInArray } from '@core/helpers/helpers';
import { CommonPhrasesEnum, OperatorTargetingPhrasesEnum, TerritoriesPhrasesEnum } from '@core/enums/localeEnum';

import './TableFilterDropdown.styles.scss';

const TableFilterDropdown: FCX<{
    options: iOption[];
    selectedOptions: ID[];
    onFilterCallback: (opts: ID[]) => void;
    onClose: (e: React.MouseEvent) => void;
}> = ({
    options,
    onClose,
    selectedOptions,
    onFilterCallback
}) => {
    const [activeSelectedOptions, setActiveSelectedOptions] = useState<ID[]>(selectedOptions);
    const { t } = useLocale();

    const {
        value,
        onChange
    } = useInput();

    const filteredOptions: iOption[] = useMemo(() => {
        const blankOption = { id: '', title: t(TerritoriesPhrasesEnum.EmptyValues) };
        const allOptions = [...options, blankOption];
        return allOptions.filter((option) => {
            if (typeof option.title === 'string') {
                return containsSubstring(option.title, value ?? '', true);
            }

            return false;
        });
    }, [options, t, value]);

    const onSelectAllActiveOptions = useCallback(
        (e?: React.MouseEvent) => {
            e?.stopPropagation();
            return setActiveSelectedOptions(filteredOptions.map((option) => option.id));
        },
        [filteredOptions],
    );

    const onClearAllActiveOptions = useCallback((e?: React.MouseEvent) => {
        e?.stopPropagation();
        return setActiveSelectedOptions([]);
    }, []);

    return (
        <div className="TableFilterDropdown" onClick={(e) => e.stopPropagation()}>
            <div className="TableFilterDropdown__heading">
                <span>Filter</span>
                <Button
                    modifiers={['naked', 'auto']}
                    onClick={onSelectAllActiveOptions}
                    isDisabled={activeSelectedOptions.length === filteredOptions.length}
                >
                    {t(OperatorTargetingPhrasesEnum.SelectAll)}
                </Button>
            </div>
            <div className="TableFilterDropdown__search">
                <Input
                    value={value}
                    onChange={onChange}
                    placeholder={t(CommonPhrasesEnum.Search)}
                    withClearIcon
                    withSearchIcon
                />
            </div>
            <div className="TableFilterDropdown__options-container">
                <div className="TableFilterDropdown__options-list">
                    <MenuList className="Inputs__options-list">
                        {!filteredOptions.length && <NoResultsFound />}
                        {filteredOptions.map((option) => {
                            const onSelect = () => setActiveSelectedOptions((prev) => toggleInArray(prev, option.id));
                            return (
                                <MenuItem
                                    key={option.id}
                                    onClick={onSelect}
                                    classes={{ root: 'Inputs__option TableFilterDropdown__option-title' }}
                                >
                                    <Toggle
                                        type={ToggleType.Checkbox}
                                        isChecked={activeSelectedOptions.includes(option.id)}
                                        setIsChecked={onSelect}
                                        className="TableFilterDropdown__option-checkbox"
                                    />
                                    {option.title}
                                </MenuItem>
                            );
                        })}
                    </MenuList>
                </div>
                <div className="TableFilterDropdown__options-footer">
                    {activeSelectedOptions.length} {t(OperatorTargetingPhrasesEnum.Selected)}
                    <Button
                        modifiers={['naked', 'auto']}
                        onClick={onClearAllActiveOptions}
                        isDisabled={activeSelectedOptions.length === 0}
                    >
                        {t(OperatorTargetingPhrasesEnum.ClearAll)}
                    </Button>
                </div>
            </div>
            <div className="TableFilterDropdown__footer">
                <Button modifiers={['secondary']} onClick={onClose}>
                    {t(CommonPhrasesEnum.Cancel)}
                </Button>
                <Button
                    modifiers={['primary']}
                    onClick={() => onFilterCallback(activeSelectedOptions)}
                    isDisabled={arraysAreEqualUnordered(activeSelectedOptions, selectedOptions)}
                >
                    {t(CommonPhrasesEnum.Apply)}
                </Button>
            </div>
        </div>
    );
};

export default TableFilterDropdown;
