import { paths } from '@core/api/api';
import useFetch from '@core/hooks/useFetch';
import { MethodsEnum, OperationsEnum, Content } from '@core/enums/api';
import { useCallback } from 'react';

const POST_TERRITORY_FAVORITE = '/territories/favorite';

type GetTerritoryFavoriteEndpoint = paths[typeof POST_TERRITORY_FAVORITE][MethodsEnum.POST];
type TerritoryFavoriteParams = GetTerritoryFavoriteEndpoint[OperationsEnum.REQUEST]['content']['application/json'];
type TerritoryFavoriteResponse =
    GetTerritoryFavoriteEndpoint[OperationsEnum.RESPONSES]['201'][Content]['application/json'];

type UseTerritoryFavoriteConfig = {
    lazy?: boolean;
    params?: TerritoryFavoriteParams;
};

export default function useTerritoryFavorite(config?: UseTerritoryFavoriteConfig) {
    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<TerritoryFavoriteResponse, TerritoryFavoriteParams>({
        url: POST_TERRITORY_FAVORITE,
        method: MethodsEnum.POST,
        params: config?.params,
        lazy: config?.lazy ?? true,
    });

    const doFetchWithParams = useCallback(
        (payload?: TerritoryFavoriteParams) => {
            return doFetch({
                data: payload,
            });
        },
        [doFetch],
    );
    return { data, loading, error, doFetch: doFetchWithParams } as const;
}
