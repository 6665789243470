import cn from 'classnames';
import MuiTableRow from '@mui/material/TableRow';

import { IconArrowDown } from '@assets/icons';
import { TranslateFunc, useLocale } from '@core/utils/locale';

import TableCell from './TableCell';
import { useTableContext } from './TableContext';
import { TableConfigItem } from './Table.helpers';
import TableFilter from './TableFilter/TableFilter';

const TableHead: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const {
        config,
        sortConfig
    } = useTableContext();
    const { t } = useLocale();

    // The primary sort column (visible in the UI) is the first column in the sortedColumns array
    const sortedColumn = sortConfig?.sortedColumns[0];

    return (
        <MuiTableRow className="Table__head" role="rowheader" data-testid="tableHeaderRow">
            {config.map((column, colIndex) => {
                const isSorted = sortedColumn?.field === column.field;
                return (
                    <TableCell
                        key={colIndex}
                        align={column.align}
                        testId={`tableHeaderColumn${colIndex}`}
                        style={column.cellStyles}
                        className={cn({
                            'Table__cell--unsortable': !column.isSortable,
                            [`Table__cell--selected-${sortedColumn?.order}`]: isSorted && column.isSortable,
                        })}
                        onClick={() => {
                            if (column.isSortable) {
                                sortConfig?.onSort(column.field);
                            }
                        }}
                    >
                        {column.isSortable && <IconArrowDown className="Table__sort-icon" />}
                        {renderColumnTitle(column, t)}
                        {column.filter && (
                            <TableFilter
                                field={column.field}
                                onFilter={column.filter.onFilter}
                                options={column.filter.filterOptions}
                                selectedOptions={column.filter.selectedOptions}
                            />
                        )}
                    </TableCell>
                );
            })}
            {children}
        </MuiTableRow>
    );
};

function renderColumnTitle(column: TableConfigItem, t: TranslateFunc) {
    if (column.renderTitle) {
        return column.renderTitle;
    }

    return column?.title ? t(column.title) : '';
}

export default TableHead;
