import { useEffect, useMemo, useState } from 'react';

import { useAdvancedSearch } from '@core/contexts';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { TabsContent } from '@components/utility/Tabs/Tabs';
import { TranslateFunc, useLocale } from '@core/utils/locale';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';
import { CriterionEnum, CriterionParamFilter } from '@core/models/Criterion';
import { MenuResponse, MenuItem, MenuHeaderTypes, MenuHeader } from '@core/api/useGlobalMenu';
import HighlightTextMatches, { type GetHighlightMatchesFn } from '@components/ui/Typography/HighlightTextMatches';
import { type SplitParts, generateAnchorId, splitTextOnMatches, getRegexMatchingConfig } from '@core/utils/string';

import MenuSection from './MenuSection';

const HIGHLIGHT_COLOR = '#FFEDBD';

const useGetMenuTabs = (menuData: MenuResponse, countryCode: CountryCodesEnum) => {
    const [keywordsHighlighted, setKeywordsHighlighted] = useState<string[]>([]);
    const { t } = useLocale();

    const { getCriterionByFilterKey } = useAdvancedSearch();

    useEffect(() => {
        const menuParams = getCriterionByFilterKey(CriterionEnum.CuisineTypes, CriterionParamFilter.MenuIncludes);

        const menuIncludesKeywords = menuParams?.map((menu) => menu?.value?.toString());

        if (menuIncludesKeywords) {
            setKeywordsHighlighted(menuIncludesKeywords);
        }
    }, [getCriterionByFilterKey]);

    const tabsContent: TabsContent[] = useMemo(() => {
        if (!menuData?.[0]) return [];

        const menuHeaders = [...getMenuHeaders(menuData), ...getOthersMenuHeader(menuData, t)];

        return mapMenuHeadersToTabs(menuHeaders, countryCode, keywordsHighlighted);
    }, [menuData, t, countryCode, keywordsHighlighted]);

    const isAllMasterHeaderEmpty = useMemo((): boolean => {
        const checkHeaders = (headers: MenuHeader[]): boolean => {
            const stack = [...headers];

            while (stack.length > 0) {
                const header = stack.pop();
                if (header) {
                    if (header.type === MenuHeaderTypes.MASTER && header.value !== '') {
                        return false;
                    }
                    if (header.headers) {
                        stack.push(...header.headers);
                    }
                }
            }

            return true;
        };

        return menuData.every((data) => data.headers && checkHeaders(data.headers));
    }, [menuData]);

    const emptyHeaderItems: MenuItem[] = useMemo(() => {
        const emptyMenuItems = menuData?.[0]?.headers ? extractMenuHeaderItems(menuData[0].headers) : [];
        return highlightMenuItems(emptyMenuItems, keywordsHighlighted);
    }, [keywordsHighlighted, menuData]);

    return { tabsContent, shouldRenderTabs: !isAllMasterHeaderEmpty, emptyHeaderItems };
};

function extractMenuHeaderItems(headers: MenuHeader[]): MenuItem[] {
    let items: MenuItem[] = [];
    headers.forEach((header) => {
        if (header.items) {
            items = items.concat(header.items);
        }
        if (header.headers) {
            items = items.concat(extractMenuHeaderItems(header.headers));
        }
    });
    return items;
}

function onTabClick(anchorId: string) {
    return () => {
        const element = document.getElementById(anchorId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
}

function getMenuHeaders(menuData: MenuResponse): MenuHeader[] {
    return menuData.flatMap(
        (data) => data.headers?.filter((master) => master.type === MenuHeaderTypes.MASTER && master.value) || [],
    );
}

function getOthersMenuHeader(menuData: MenuResponse, t: TranslateFunc): MenuHeader[] {
    const otherSectionItems = extractMenuHeaderItems(
        menuData.flatMap(
            (data) => data.headers?.filter((header) => header.type === MenuHeaderTypes.MASTER && !header.value) || [],
        ),
    );
    if (otherSectionItems.length > 0) {
        return [];
    }

    return [
        {
            value: t(OperatorProfilePhrasesEnum.Others),
            headers: [{ type: MenuHeaderTypes.MASTER, value: '', items: otherSectionItems }],
        },
    ];
}

function highlightMenuItems(menuItems: MenuItem[], keywordsHighlighted: string[]): MenuItem[] {
    if (keywordsHighlighted.length === 0) {
        return menuItems;
    }

    const highlightedMenuItems = menuItems.map((item) => {
        return {
            ...item,
            name: (
                <HighlightTextMatches
                    text={item.name as string}
                    highlight={keywordsHighlighted}
                    customMatchFunction={customKeywordMatchFunction}
                    color={HIGHLIGHT_COLOR}
                />
            ),
            ...(item?.description
                ? {
                    description: (
                        <HighlightTextMatches
                            text={item.description as string}
                            highlight={keywordsHighlighted}
                            customMatchFunction={customKeywordMatchFunction}
                            color={HIGHLIGHT_COLOR}
                        />
                    ),
                }
                : {}),
        };
    });

    return highlightedMenuItems;
}

function mapMenuHeadersToTabs(
    menuHeaders: MenuHeader[],
    countryCode: CountryCodesEnum,
    keywordsHighlighted: string[],
): TabsContent[] {
    return menuHeaders.map((masterHeader) => {
        const anchorId = generateAnchorId(masterHeader.value, 'menu-tab-');
        const items = extractMenuHeaderItems(masterHeader.headers || []);
        const highlightedMenuItems = highlightMenuItems(items, keywordsHighlighted);

        return {
            label: <span data-testid={`heading${masterHeader.value}`}>{masterHeader.value}</span>,
            title: masterHeader.value,
            content: (
                <MenuSection
                    countryCode={countryCode}
                    title={masterHeader.value}
                    items={highlightedMenuItems}
                    sectionAnchorId={anchorId}
                />
            ),
            onClick: onTabClick(anchorId),
        };
    });
}

/**
 * Custom function to match and split a string based on given keywords.
 * It handles both exact matches (keywords enclosed in double quotes) and partial matches.
 * The function first splits the string based on exact matches and then splits the unmatched parts based on partial matches.
 *
 * @param {string} str - The string to be split based on the keywords.
 * @param {string | string[]} keywords - The keywords to match against the string.
 *        Exact matches should be enclosed in double quotes.
 * @returns {SplitParts} - An array of split parts, each part indicating whether it is a match or not.
 */
const customKeywordMatchFunction: GetHighlightMatchesFn = (str, keywords) => {
    const patterns = Array.isArray(keywords) ? keywords : [keywords];

    const partialMatches = patterns?.filter((keyword) => !/^".*"$/.test(keyword)).map((keyword) => keyword);

    const exactMatches = patterns
        ?.filter((keyword) => /^".*"$/.test(keyword))
        .map((keyword) => keyword?.replace(/^"(.*)"$/, '$1'));

    if (exactMatches?.length > 0) {
        const exactSplit = getExactMatches(str, exactMatches);

        const partialAndExactMatches = exactSplit.reduce((splitPartsAcc, part) => {
            if (part.isMatch) return splitPartsAcc.concat(part);

            const unmatchedParts = getAllPartialMatches(part.text, partialMatches);
            return splitPartsAcc.concat(unmatchedParts);
        }, [] as SplitParts);

        return partialAndExactMatches;
    }

    return getAllPartialMatches(str, partialMatches);
};

const getAllPartialMatches = (str: string, patterns: string[]) => {
    const {
        matchStr,
        regex
    } = getRegexMatchingConfig(str, patterns, { removeAccents: true, isGlobal: true });

    return splitTextOnMatches(matchStr, regex);
};

const getExactMatches = (str: string, patterns: string[]) => {
    const { regex } = getRegexMatchingConfig(str, patterns, {
        removeAccents: false,
        isGlobal: true,
        regexLookBehind: '(?<!\\S)', // Negative lookbehind for non-whitespace
        regexLookAhead: '(?!\\S)', // Negative lookahead for non-whitespace
    });

    return splitTextOnMatches(str, regex);
};

export default useGetMenuTabs;
