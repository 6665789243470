import { CSSProperties } from 'react';

import { FCX } from '@core/models';
import { SplitParts, formatTestIds, splitTextOnMatches, getRegexMatchingConfig } from '@core/utils/string';

const DEFAULT_HIGHLIGHT_COLOR = '#D2F6E8';

export type GetHighlightMatchesFn = (str: string, highlight: string | string[], template?: boolean) => SplitParts;

function matchHighlightParts(
    text: string,
    matchPattern: string | string[],
    onAllMatches: boolean,
    customMatchFunction?: GetHighlightMatchesFn,
): SplitParts {
    if ((Array.isArray(matchPattern) && matchPattern.length === 0) || !text) {
        return [{ id: 0, text, isMatch: false }];
    }

    if (customMatchFunction) {
        return customMatchFunction(text, matchPattern);
    }

    const {
        matchStr,
        regex
    } = getRegexMatchingConfig(text, matchPattern, {
        removeAccents: true,
        isGlobal: onAllMatches,
    });

    return splitTextOnMatches(text, regex, matchStr);
}

function renderHighlightedText(splitPart: SplitParts[number], color = DEFAULT_HIGHLIGHT_COLOR) {
    if (splitPart.isMatch) {
        return <span style={splitPart.isMatch ? { background: color } : undefined}>{splitPart.text}</span>;
    }

    return splitPart.text;
}

const HighlightTextMatches: FCX<{
    color?: CSSProperties['color'];
    customMatchFunction?: GetHighlightMatchesFn;
    highlight: string | string[];
    onAllMatches?: boolean;
    text: string;
}> = ({
    color,
    customMatchFunction,
    highlight,
    onAllMatches = true,
    text,
    className,
    style,
    testId
}) => {
    const textHighlightParts = matchHighlightParts(text, highlight, onAllMatches, customMatchFunction);

    return (
        <span style={style} className={className} data-testid={`highlightTextMatches${formatTestIds(testId)}`}>
            {textHighlightParts.map((part) => renderHighlightedText(part, color))}
        </span>
    );
};

export default HighlightTextMatches;
